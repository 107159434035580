import React, { useState, useEffect } from "react";
import {
  DataTable,
  Image,
  DisplayText,
  Card,
  TextStyle,
  Pagination,
  Stack,
  Icon,
  Subheading,
} from "@shopify/polaris";

import Cookies from "js-cookie";
import axios from "../Assets/Libraries/axios";
import {} from "@shopify/polaris-icons";
import emptyIcon from "../Assets/Images/emptyList.svg";
import EllipsisText from "react-ellipsis-text";
import { useParams } from "react-router-dom";
import { ListMajor } from "@shopify/polaris-icons";
import moment from "moment";
import { func } from "prop-types";
import ReactHtmlParser from "react-html-parser";

const History = (props) => {
  console.log(props);
  const format = "YYYY-MM-DD HH:mm:ss";
  let { id } = useParams();
  const [totalPages, setTotalPages] = useState(0);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 5;
  useEffect(() => {
    if (props.countries.length !== 0) {
      console.log("aaa");

      axios
        .get(
          `admin/v1/leads/${id}/histories?per_page=${perPage}&page=${currentPage}`
        )
        .then((result) => {
          if (result.data.data.length === 0) {
            setIsListEmpty(true);
          } else {
            setIsListEmpty(false);
          }
          setTotalPages(Math.ceil(result.data.data.total / perPage));
          //console.log(" result.data.data.data=", result.data.data.data);
          setItems(
            result.data.data.data.map((item) => [
              item.ip,
              moment(item.created_at).format(format),
              moment(item.updated_at).format(format),
              item.action,
              item.changes &&
              typeof item.changes === "object" &&
              !Array.isArray(item.changes)
                ? ReactHtmlParser(handleChanges(item.changes, item.action))
                : "",
            ])
          );
        })
        .catch((err) => console.log(err));
    }
  }, [currentPage, props]);

  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <Card title="HISTORY">
      <DataTable
        columnContentTypes={["text", "text", "text", "text"]}
        headings={[
          <TextStyle variation="strong">IP</TextStyle>,
          <TextStyle variation="strong">Created at</TextStyle>,
          <TextStyle variation="strong">Updated at</TextStyle>,
          <TextStyle variation="strong">Action</TextStyle>,
          <TextStyle variation="strong">Changes</TextStyle>,
        ]}
        rows={items}
        sortable={[false, false, false, false, false, false, false]}
        defaultSortDirection="descending"
        verticalAlign="middle"
      />
      {totalPages >= 2 && (
        <div
          style={{
            paddingTop: "10px",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          <Pagination
            hasPrevious={currentPage > 1 && true}
            onPrevious={() => {
              setCurrentPage(currentPage - 1);
            }}
            hasNext={currentPage < totalPages && true}
            onNext={() => {
              setCurrentPage(currentPage + 1);
            }}
          />
        </div>
      )}
    </Card>
  );
  function switchProfileKeys(param) {
    console.log(param);
    switch (param) {
      case "first_name":
        return "First Name";
      case "title_id":
        return "Title";
      case "last_name":
        return "Last Name";
      case "email":
        return "Email";
      case "personal_mobile_number":
        return "Personal Mobile Number";
      case "date_of_birth":
        return "Date of birth";
      case "title":
        return "Title";
      case "annual_income":
        return "Annual Income";
      case "employer_address":
        return "Employer Address";
      case "employer_name":
        return "Employer Name";
      case "employer_telephone":
        return "Employer Telephone";
      case "employment_status":
        return "Employer Status";
      case "net_worth":
        return "Net Worth";
      case "other_source_of_wealth":
        return "Other source of wealth";
      case "position_title":
        return "Position Title";
      case "previous_position_title":
        return "Previous Position Title";
      case "source_of_wealth":
        return "Source of wealth";
      case "estimate_deposite_size":
        return "Estimate Deposite Size";
      case "academic":
        return "Academic";
      case "beneficiary":
        return "Beneficiary";
      case "director":
        return "Director";
      case "qualifications":
        return "Qualifications";
      case "relevant_experience":
        return "Relevant Experience";
      case "address":
        return "Address";
      case "city":
        return "City";
      case "country_of_residence":
        return "Country of residence";
      case "identification_number":
        return "Identification Number";
      case "identification_type":
        return "Identification Type";
      case "nationality":
        return "Nationality";
      case "us_citizen":
        return "Us Citizen";
      case "account_purpose":
        return "Account Purpose";
      case "cfd_foreign_exchange":
        return "Cfd Foreign Exhange";
      case "futures":
        return "Futures";
      case "how_often_do_you_expext_to_trade":
        return "How often do you expext to trade";
      case "id":
        return "Trading Experience Id";
      case "other_account_purpose":
        return "Other account purpose";
      case "understanding":
        return "understanding";

      default:
      // code block
    }
  }
  function switchIDs(key, value) {
    switch (key) {
      case "nationality":
        return findArrayElementById(props.countries, value)
          ? findArrayElementById(props.countries, value).value
          : "";
      case "country_of_residence":
        return findArrayElementById(props.countries, value)
          ? findArrayElementById(props.countries, value).value
          : "";
      case "futures":
        return switchFuturesAndOptions(value);
      case "cfd_foreign_exchange":
        return switchDerivatives(value);
      case "title_id":
        return switchTitle(value);
      case "id":
        return props.regulators === "lebanon"
          ? getTardingExperienceIDlebanon(value)
          : getTardingExperienceIDseychelles(value);
      case "identification_type":
        return props.regulators === "lebanon"
          ? getIdentificationTypeLebanon(value)
          : getIdentificationTypeSeychelles(value);
      case "net_worth":
        return props.regulators === "lebanon"
          ? getNetWorthLebanon(value)
          : getNetWorthSeychelles(value);
      case "annual_income":
        return props.regulators === "lebanon"
          ? getAnnualIncomeLebanon(value)
          : getAnnualIncomeSeychelles(value);
      case "source_of_wealth":
        return getSourceOfWealth(value);
      case "account_purpose":
        return getMainPurposeOfTheAccount(value);
      case "employment_status":
        return getEmploymentStatus(value);
      default:
        return "";
    }
  }
  function handleChanges(param, actionStr) {
    let str = "";
    for (const [key, value] of Object.entries(param)) {
      //console.log(`${key}: ${value["old value"]}:${value["new value"]}`);
      if (typeof value === "object") {
        if ("old value" in value) {
          str += `<p><b>${switchProfileKeys(
            String(key)
          )}</b> field was changed from <b>${
            String(key) !== "nationality" &&
            String(key) !== "country_of_residence" &&
            String(key) !== "futures" &&
            String(key) !== "cfd_foreign_exchange" &&
            String(key) !== "title_id" &&
            String(key) !== "id" &&
            String(key) !== "identification_type" &&
            String(key) !== "net_worth" &&
            String(key) !== "annual_income" &&
            String(key) !== "source_of_wealth" &&
            String(key) !== "account_purpose" &&
            String(key) !== "employment_status"
              ? value["old value"]
              : switchIDs(String(key), value["old value"])
          }</b> to <b>${
            String(key) !== "nationality" &&
            String(key) !== "country_of_residence" &&
            String(key) !== "futures" &&
            String(key) !== "cfd_foreign_exchange" &&
            String(key) !== "title_id" &&
            String(key) !== "id" &&
            String(key) !== "identification_type" &&
            String(key) !== "net_worth" &&
            String(key) !== "annual_income" &&
            String(key) !== "source_of_wealth" &&
            String(key) !== "account_purpose" &&
            String(key) !== "employment_status"
              ? value["new value"]
              : switchIDs(String(key), value["new value"])
          }</b></p>`;
        } else {
          for (var i = 0; i < Object.entries(value).length; i++) {
            // console.log("i===", i, "=", Object.entries(value)[i]);
            if (typeof Object.entries(value)[i][1] === "object") {
              str += `<p><b>${switchProfileKeys(
                Object.entries(value)[i][0]
              )}</b> field was changed from <b>${
                Object.entries(value)[i][0] !== "nationality" &&
                Object.entries(value)[i][0] !== "country_of_residence" &&
                Object.entries(value)[i][0] !== "futures" &&
                Object.entries(value)[i][0] !== "cfd_foreign_exchange" &&
                Object.entries(value)[i][0] !== "title_id" &&
                Object.entries(value)[i][0] !== "id" &&
                Object.entries(value)[i][0] !== "identification_type" &&
                Object.entries(value)[i][0] !== "net_worth" &&
                Object.entries(value)[i][0] !== "annual_income" &&
                Object.entries(value)[i][0] !== "source_of_wealth" &&
                Object.entries(value)[i][0] !== "account_purpose" &&
                Object.entries(value)[i][0] !== "employment_status"
                  ? Object.entries(value)[i][1]["old value"]
                  : switchIDs(
                      Object.entries(value)[i][0],
                      Object.entries(value)[i][1]["old value"]
                    )
              }</b> to <b>${
                Object.entries(value)[i][0] !== "nationality" &&
                Object.entries(value)[i][0] !== "country_of_residence" &&
                Object.entries(value)[i][0] !== "futures" &&
                Object.entries(value)[i][0] !== "cfd_foreign_exchange" &&
                Object.entries(value)[i][0] !== "title_id" &&
                Object.entries(value)[i][0] !== "id" &&
                Object.entries(value)[i][0] !== "identification_type" &&
                Object.entries(value)[i][0] !== "net_worth" &&
                Object.entries(value)[i][0] !== "annual_income" &&
                Object.entries(value)[i][0] !== "source_of_wealth" &&
                Object.entries(value)[i][0] !== "account_purpose" &&
                Object.entries(value)[i][0] !== "employment_status"
                  ? Object.entries(value)[i][1]["new value"]
                  : switchIDs(
                      Object.entries(value)[i][0],
                      Object.entries(value)[i][1]["new value"]
                    )
              }</b></p>`;
            }
          }
        }
      } else {
        // if (value.includes("old value")) {
        //   Object.keys(param).indexOf(key) < Object.keys(param).length - 1
        //     ? (str += `<p><b>${switchProfileKeys(
        //         String(key)
        //       )}</b> field was changed from <b>${
        //         value.split(",")[0].split("=")[1]
        //       }</b> to <b>${value.split(",")[1].split("=")[1]}</b></p></br>`)
        //     : (str += `<p><b>${switchProfileKeys(
        //         String(key)
        //       )}</b> field was changed from <b>${
        //         value.split(",")[0].split("=")[1]
        //       }</b> to <b>${value.split(",")[1].split("=")[1]}</b></p>`);
        // } else {
        //   str += "";
        // }
      }
    }

    return str;
  }

  function switchFuturesAndOptions(param) {
    switch (String(param)) {
      case "never":
        return "Never";
      case "1 to 15 occasions":
        return "1 to 15 occasions";
      case "15 to 40 occasions":
        return "15 to 40 occasions";
      case "More than 40 occasions":
        return "More than 40 occasions";

      default:
        return "";
    }
  }
  function switchDerivatives(param) {
    switch (String(param)) {
      case "never":
        return "Never";
      case "1 to 15 occasions":
        return "1 to 15 occasions";
      case "15 to 40 occasions":
        return "15 to 40 occasions";
      case "More than 40 occasions":
        return "More than 40 occasions";

      default:
        return "";
    }
  }
  function switchFuturesAndOptions(param) {
    switch (String(param)) {
      case "never":
        return "Never";
      case "1 to 15 occasions":
        return "1 to 15 occasions";
      case "15 to 40 occasions":
        return "15 to 40 occasions";
      case "More than 40 occasions":
        return "More than 40 occasions";

      default:
        return "";
    }
  }
  function switchTitle(param) {
    switch (String(param)) {
      case "1":
        return "Mr.";
      case "2":
        return "Mrs.";
      case "4":
        return "Ms.";
      case "5":
        return "Dr.";
      case "6":
        return "Prof.";
      default:
        return "";
    }
  }
  function getTardingExperienceIDlebanon(param) {
    switch (param) {
      case "30":
        return "None";
      case "31":
        return "1 - 3 Years";
      case "32":
        return "3 - 5 Years";
      case "33":
        return "5+ Years";
      default:
        return "";
    }
  }
  function getTardingExperienceIDseychelles(param) {
    switch (param) {
      case "34":
        return "None";
      case "35":
        return "1 - 3 Years";
      case "36":
        return "3 - 4 Years";
      case "37":
        return "5+ Years";
      default:
        return "";
    }
  }
  function getIdentificationTypeLebanon(param) {
    switch (param) {
      case "203":
        return "Passport";
      case "204":
        return "Drivers License";
      case "205":
        return "Government ID";

      default:
        return "";
    }
  }
  function getIdentificationTypeSeychelles(param) {
    switch (param) {
      case "212":
        return "Passport";
      case "213":
        return "Drivers License";
      case "214":
        return "Government ID";

      default:
        return "";
    }
  }
  function getNetWorthLebanon(param) {
    switch (param) {
      case "323":
        return "Less than $25,000";
      case "324":
        return "$25,000-$100,000";
      case "325":
        return "$100,001-$500,000";
      case "326":
        return "Over $500,000";
      default:
        return "";
    }
  }
  function getNetWorthSeychelles(param) {
    switch (param) {
      case "335":
        return "Less than $25,000";
      case "336":
        return "$25,000-$100,000";
      case "337":
        return "$100,001-$500,000";
      case "338":
        return "$Over $500,000";
      default:
        return "";
    }
  }
  function getAnnualIncomeLebanon(param) {
    switch (param) {
      case "305":
        return "Less than $15,000";
      case "306":
        return "$15,000-$50,000";
      case "307":
        return "$50,001-$100,000";
      case "308":
        return "Over $100,000";
      default:
        return "";
    }
  }
  //
  function getAnnualIncomeSeychelles(param) {
    switch (param) {
      case "317":
        return "Less than $15,000";
      case "318":
        return "$15,000-$50,000";
      case "319":
        return "$50,001-$100,000";
      case "320":
        return "Over $100,000";
      default:
        return "";
    }
  }
  function getSourceOfWealth(param) {
    switch (param) {
      case "1":
        return "Employment";
      case "2":
        return "Investment";
      case "3":
        return "Inheritance";
      case "4":
        return "Other";
      case "28":
        return "Pension";
      default:
        return "";
    }
  }
  function getMainPurposeOfTheAccount(param) {
    switch (param) {
      case "3":
        return "Capital Growth'";
      case "4":
        return "Speculation";
      case "5":
        return "Income";
      case "6":
        return "Hedging";
      case "7":
        return "Other";
      default:
        return "";
    }
  }
  function getEmploymentStatus(param) {
    switch (param) {
      case "1":
        return "Employed";
      case "2":
        return "Unemployed";
      case "3":
        return "Self-Employed";
      case "4":
        return "Retired";
      case "5":
        return "Student";
      default:
        return "";
    }
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
};
export default React.memo(History);
