import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  // Select,
} from "@shopify/polaris";
import axios from "../../Assets/Libraries/axios";
import history from "../../Assets/Libraries/history";
import axios2 from "axios";
// import SelectSearch from "react-select-search";
import { useParams } from "react-router-dom";
import Select from "react-select";

const Add = () => {
  const { id } = useParams();
  const [selectedSymbol, setSelectedSymbol] = useState("");

  const handleSelectChangeSymbol = (value) => {
    setSelectedSymbol(value);
    setSymbolError("");
  };
  const [symbolError, setSymbolError] = useState("");
  const [optionsSymbols, setOptionsSymbols] = useState([]);

  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    axios2
      .get(`https://mt5mailchimp.creo-dev.com/api/mt5-mailchimp?symbols=*`)
      .then((result) => {
        console.log(result.data.statData);
        setOptionsSymbols((currentObjects) =>
          currentObjects.concat([
            ...result.data.statData.map((item, index) => {
              return {
                label: item,
                value: String(item),
              };
            }),
          ])
        );
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Add Symbol"
      breadcrumbs={[{ url: `/live-market-prices/${id}/symbols` }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          {/* <SelectSearch
            options={optionsSymbols}
            value={selectedSymbol}
            onChange={handleSelectChangeSymbol}
            name="language"
            placeholder="Please choose an option"
          /> */}

          <Select
            label="Symbol"
            options={optionsSymbols}
            onChange={handleSelectChangeSymbol}
            value={selectedSymbol}
            placeholder="Please select"
          />
          <InlineError message={symbolError} />
          <Checkbox
            label="Is active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (selectedSymbol === "") {
      setSymbolError("Please select a symbol");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      form_data.append("is_ative", checkedShow ? 1 : 0);
      form_data.append("symbol", selectedSymbol.value);
      form_data.append("asset_id", id);
      axios
        .post("/admin/v1/asset-details", form_data)
        .then((res) => {
          history.push(`/live-market-prices/${id}/symbols`);
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
