import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../Assets/Libraries/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../Components/NavigationLayout";
import Edit from "./Edit";
import Symbols from "./Symbols/Main";

const MainEdit = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/live-market-prices/:id" component={Edit} />
        <Route path="/live-market-prices/:id/symbols" component={Symbols} />
      </Switch>
    </Router>
  );
};
export default MainEdit;
