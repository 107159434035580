import React, { useCallback, useState, useEffect } from "react";
import { Page, Card, Layout, Modal } from "@shopify/polaris";

import {
  FormLayout,
  InlineError,
  TextStyle,
  TextField,
  PageActions,
  Toast,
  DataTable,
  Badge,
} from "@shopify/polaris";
import axios from "./Assets/Libraries/axios";
import Cookies from "js-cookie";
import ReactTagInput from "@pathofdev/react-tag-input";
import NavigationLayout from "./Components/NavigationLayout";
import TagsInput from "react-tagsinput";

function Settings() {
  var PromiseAll = require("promises-all");
  const [valueFacebookLink, setValueFacebookLink] = useState("");
  const [valueInstagramLink, setValueInstagramLink] = useState("");
  const [valueSnapchatLink, setValueSnapchatLink] = useState("");
  const [valueYoutubeLink, setValueYoutubeLink] = useState("");
  const [valueLeadEmail, setValueLeadEmail] = useState("");
  const [valueClientEmail, setValueClientEmail] = useState("");
  const [valuePhoneNumber, setValuePhoneNumber] = useState("");
  const [valueEmail, setValueEmail] = useState("");
  const [usersItems, setUsersItems] = useState([]);
  const [active, setActive] = useState(false);
  const [toastContent, setToastContent] = useState("Settings Saved");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [convertValue, setConvertValue] = useState("");
  const [convertDrawalValue, setConvertDrawalValue] = useState("");
  const [convertError, setConvertError] = useState("");
  const [convertDrawalError, setConvertDrawalError] = useState("");

  const [convertValueAED, setConvertValueAED] = useState("");
  const [convertDrawalValueAED, setConvertDrawalValueAED] = useState("");
  const [convertErrorAED, setConvertErrorAED] = useState("");
  const [convertDrawalErrorAED, setConvertDrawalErrorAED] = useState("");

  const toastMarkup = active ? (
    <Toast content={toastContent} onDismiss={toggleActive} />
  ) : null;

  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [popupActive, setPopupActive] = useState(false);
  const [tagsLeadsEmails, setTagsLeadsEmails] = useState([]);
  const [tagsClientsEmails, setTagsClientsEmails] = useState([]);
  const [tagsClientDocumentEmails, setTagsClientDocumentEmails] = useState([]);
  const [arrayDocumentClientEmails, setArrayDocumentClientEmails] = useState(
    []
  );
  const [arrayLeads, setArrayLeads] = useState([]);
  const [arrayClients, setArrayClients] = useState([]);
  function handleChangeConvertDrawalValue(checked) {
    setConvertDrawalValue(checked);
    setConvertDrawalError("");
  }
  function handleChangeConvertValue(checked) {
    setConvertValue(checked);
    setConvertError("");
  }

  function handleChangeConvertDrawalValueAED(checked) {
    setConvertDrawalValueAED(checked);
    setConvertDrawalErrorAED("");
  }
  function handleChangeConvertValueAED(checked) {
    setConvertValueAED(checked);
    setConvertErrorAED("");
  }
  useEffect(() => {
    setPopupActive(true);
    PromiseAll.all([getSettings(), getAdminUsers()]).then(function (response) {
      setPopupActive(false);
      console.log(response);
      const responseSettings = response.resolve[0];
      const responseUsers = response.resolve[1];
      typeof responseSettings === "object" &&
        responseSettings.social_media &&
        setValueFacebookLink(responseSettings.social_media.Facebook);
      typeof responseSettings === "object" &&
        responseSettings.usd_to_xof &&
        setConvertValue(String(responseSettings.usd_to_xof));
      typeof responseSettings === "object" &&
        responseSettings.usd_to_xof_withdrawal &&
        setConvertDrawalValue(String(responseSettings.usd_to_xof_withdrawal));

      typeof responseSettings === "object" &&
        responseSettings.usd_to_aed &&
        setConvertValueAED(String(responseSettings.usd_to_aed));
      typeof responseSettings === "object" &&
        responseSettings.usd_to_aed_withdrawal &&
        setConvertDrawalValueAED(
          String(responseSettings.usd_to_aed_withdrawal)
        );

      typeof responseSettings === "object" &&
        responseSettings.social_media &&
        setValueInstagramLink(responseSettings.social_media.Instagram);
      typeof responseSettings === "object" &&
        responseSettings.social_media &&
        setValueSnapchatLink(responseSettings.social_media.Snapchat);
      typeof responseSettings === "object" &&
        responseSettings.social_media &&
        setValueYoutubeLink(responseSettings.social_media.Youtube);
      typeof responseSettings === "object" &&
        responseSettings.contact_phone &&
        setValuePhoneNumber(responseSettings.contact_phone);
      typeof responseSettings === "object" &&
        responseSettings.contact_email &&
        setValueEmail(responseSettings.contact_email);
      typeof responseSettings === "object" &&
        responseSettings.lead_email &&
        setTagsLeadsEmails(responseSettings.lead_email);
      typeof responseSettings === "object" &&
        responseSettings.client_document_email &&
        setTagsClientDocumentEmails(responseSettings.client_document_email);
      typeof responseSettings === "object" &&
        responseSettings.client_email &&
        setTagsClientsEmails(responseSettings.client_email);
      typeof responseSettings === "object" &&
        responseSettings.lead_email &&
        setArrayLeads(responseSettings.lead_email);
      typeof responseSettings === "object" &&
        responseSettings.client_email &&
        setArrayClients(responseSettings.client_email);
      typeof responseSettings === "object" &&
        responseSettings.client_document_email &&
        setArrayDocumentClientEmails(responseSettings.client_document_email);
      typeof responseUsers === "object" &&
        setUsersItems(
          responseUsers.data.map((item) => [
            item.id,
            item.first_name,
            item.last_name,
            item.roles && <Badge>{item.roles[0].name}</Badge>,
          ])
        );
    });
  }, []);

  function getSettings() {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/v1/settings")
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 0);
    });
  }

  function getAdminUsers() {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        axios
          .get("/admin/v1/management/users")
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (err) {
            resolve(err.message);
          });
      }, 500);
    });
  }
  return (
    <NavigationLayout>
      <Page>
        <Layout>
          <Layout.AnnotatedSection title="Social Media">
            <Card sectioned>
              <FormLayout>
                <TextStyle variation="strong">Facebook Link</TextStyle>
                <TextField
                  value={valueFacebookLink}
                  onChange={handleChangeFacebookLink}
                />
                <TextStyle variation="strong">Instagram Link</TextStyle>
                <TextField
                  value={valueInstagramLink}
                  onChange={handleChangeInstagramLink}
                />
                <TextStyle variation="strong">Twitter Link</TextStyle>
                <TextField
                  value={valueSnapchatLink}
                  onChange={handleChangeSnapchatLink}
                />
                <TextStyle variation="strong">Youtube Link</TextStyle>
                <TextField
                  value={valueYoutubeLink}
                  onChange={handleChangeYoutubeLink}
                />
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection title="Emails">
            <Card sectioned>
              <FormLayout>
                <TextStyle variation="strong">Leads Emails</TextStyle>
                {/* <TextField
                  value={valueLeadEmail}
                  onChange={handleChangeLeadEmail}
                /> */}
                {/* <ReactTagInput
                  tags={tagsLeadsEmails}
                  onChange={handleChangeTagsLeadsEmails}
                /> */}
                <TagsInput
                  value={tagsLeadsEmails}
                  onChange={handleChangeTagsLeadsEmails}
                />

                <TextStyle variation="strong">Clients Emails</TextStyle>
                <TagsInput
                  value={tagsClientsEmails}
                  onChange={handleChangeTagsClientsEmails}
                />
                <TextStyle variation="strong">Client Document Emails</TextStyle>
                <TagsInput
                  value={tagsClientDocumentEmails}
                  onChange={handleChangeTagsClientDocumentEmails}
                />
                {/* <TextField
                  value={valueClientEmail}
                  onChange={handleChangeClientEmail}
                /> */}
                {/* <ReactTagInput
                  tags={tagsClientsEmails}
                  onChange={handleChangeTagsClientsEmails}
                /> */}
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection title="Contact Us">
            <Card sectioned>
              <FormLayout>
                <TextStyle type="number" variation="strong">
                  Phone Number
                </TextStyle>
                <TextField
                  value={valuePhoneNumber}
                  onChange={handleChangePhoneNumber}
                />
                {fieldRequiredP}

                <TextStyle variation="strong">Email</TextStyle>
                <TextField value={valueEmail} onChange={handleChangeEmail} />
                {fieldRequiredE}
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection title="USD to XOF">
            <Card sectioned>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">USD to XOF (Deposit)</TextStyle>
                  <TextField
                    value={convertValue}
                    onChange={handleChangeConvertValue}
                    type="number"
                    error={convertError}
                  />
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    USD to XOF (Withdrawal)
                  </TextStyle>
                  <TextField
                    value={convertDrawalValue}
                    onChange={handleChangeConvertDrawalValue}
                    type="number"
                    error={convertDrawalError}
                  />
                </FormLayout>
              </FormLayout.Group>
            </Card>
          </Layout.AnnotatedSection>

          <Layout.AnnotatedSection title="USD to AED">
            <Card sectioned>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">USD to AED (Deposit)</TextStyle>
                  <TextField
                    value={convertValueAED}
                    onChange={handleChangeConvertValueAED}
                    type="number"
                    error={convertErrorAED}
                  />
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">
                    USD to AED (Withdrawal)
                  </TextStyle>
                  <TextField
                    value={convertDrawalValueAED}
                    onChange={handleChangeConvertDrawalValueAED}
                    type="number"
                    error={convertDrawalErrorAED}
                  />
                </FormLayout>
              </FormLayout.Group>
            </Card>
          </Layout.AnnotatedSection>

          {Cookies.get("4t-adminrole") !== "editor" && (
            <Layout.AnnotatedSection title="Admin Users">
              <Card
                actions={[
                  {
                    content: "Show All",
                    url: "/adminusers",
                  },
                ]}
              >
                <DataTable
                  columnContentTypes={["text", "text", "text", "text"]}
                  headings={[
                    <TextStyle variation="strong">ID</TextStyle>,
                    <TextStyle variation="strong">First Name</TextStyle>,
                    <TextStyle variation="strong">Last Name</TextStyle>,
                    <TextStyle variation="strong">Role</TextStyle>,
                  ]}
                  rows={usersItems}
                />
              </Card>
            </Layout.AnnotatedSection>
          )}
        </Layout>
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSubmit,
          }}
        />
        {toastMarkup}
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleChangeTagsLeadsEmails(tagsLeadsEmails) {
    console.log(tagsLeadsEmails);
    setTagsLeadsEmails(tagsLeadsEmails);
    setArrayLeads(tagsLeadsEmails);
  }
  function handleChangeTagsClientsEmails(tagsClientsEmails) {
    setTagsClientsEmails(tagsClientsEmails);
    setArrayClients(tagsClientsEmails);
  }

  function handleChangeTagsClientDocumentEmails(tagsClientDocumentEmails) {
    setTagsClientDocumentEmails(tagsClientDocumentEmails);
    setArrayDocumentClientEmails(tagsClientDocumentEmails);
  }
  function handleChangeClientEmail(valueClientEmail) {
    setValueClientEmail(valueClientEmail);
  }
  function handleChangeLeadEmail(valueLeadEmail) {
    setValueLeadEmail(valueLeadEmail);
  }
  function handleChangeFacebookLink(valueFacebookLink) {
    setValueFacebookLink(valueFacebookLink);
  }
  function handleChangeInstagramLink(valueButtonText) {
    setValueInstagramLink(valueButtonText);
  }
  function handleChangeSnapchatLink(valueSnapchatLink) {
    setValueSnapchatLink(valueSnapchatLink);
  }
  function handleChangeYoutubeLink(valueYoutubeLink) {
    setValueYoutubeLink(valueYoutubeLink);
  }

  function handleChangePhoneNumber(valueButtonText) {
    setValuePhoneNumber(valueButtonText);
  }
  function handleChangeEmail(checked) {
    setValueEmail(checked);
  }

  function handleSubmit() {
    if (
      (valueEmail !== null &&
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      (valuePhoneNumber !== null &&
        valuePhoneNumber !== "" &&
        valuePhoneNumber.length !== 8) ||
      (convertValue && convertValue % 5 !== 0) ||
      (convertDrawalValue && convertDrawalValue % 5 !== 0) ||
      !convertDrawalValueAED
    ) {
      !convertDrawalValueAED &&
        setConvertDrawalErrorAED("This field is required");
      if (
        valueEmail !== null &&
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      )
        setFieldRequiredE(
          <InlineError message="Invalid email" fieldID="myFieldID" />
        );

      if (
        valuePhoneNumber !== null &&
        valuePhoneNumber !== "" &&
        valuePhoneNumber.length !== 8
      )
        setFieldRequiredP(
          <InlineError message="Phone length must be 8" fieldID="myFieldID" />
        );
      convertValue &&
        convertValue % 5 !== 0 &&
        setConvertError("The number should be a multiple of 5");
      convertDrawalValue &&
        convertDrawalValue % 5 !== 0 &&
        setConvertDrawalError("The number should be a multiple of 5");
    } else {
      setPopupActive(true);
      const body = {
        social_media: {
          Facebook: valueFacebookLink,
          Instagram: valueInstagramLink,
          Snapchat: valueSnapchatLink,
          Youtube: valueYoutubeLink,
        },
        contact_phone: valuePhoneNumber,
        contact_email: valueEmail,
        client_email: arrayClients,
        lead_email: arrayLeads,
        client_document_email: arrayDocumentClientEmails,
        usd_to_xof: convertValue,
        usd_to_xof_withdrawal: convertDrawalValue,
        usd_to_aed: convertValueAED,
        usd_to_aed_withdrawal: convertDrawalValueAED,
      };

      axios
        .post("/admin/v1/settings", body)
        .then((res) => {
          setPopupActive(false);
          setToastContent("Page Saved");
          toggleActive();
        })
        .catch((err) => console.log(err));
    }
  }
}
export default Settings;
