import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../Assets/Libraries/history";
import List from "./List";
import NavigationLayout from "../Components/NavigationLayout";
import MainEditLeads from "./MainEditLeads";
function MainLeads() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/leads">
            <List type={"leads"} />
          </Route>
          <Route path="/leads/:id">
            <MainEditLeads type={"leads"} />
          </Route>
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default MainLeads;
