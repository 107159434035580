import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Layout,
  Card,
  FormLayout,
  TextField,
  Page,
  InlineError,
  Toast,
  TextStyle,
  PageActions,
} from "@shopify/polaris";
import axios from "./Assets/Libraries/axios";
import Cookies from "js-cookie";
import history from "./Assets/Libraries/history";
import NavigationLayout from "./Components/NavigationLayout";

export default function Profile() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);

  const [errorPasswordTf, setErrorPasswordTf] = useState(<h1></h1>);
  const [paginationVar, setPagination] = useState(1);

  let page = 1;
  let per_page = 4;
  const firstPage = 1;
  const [myPageNb, setMyPageNb] = useState(1);
  const [perPage, setPerPage] = useState(13);

  const [lastPage, setLastPage] = useState("");

  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");

  const [items, setItems] = useState([]);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [checkedNotifications, setCheckedNotifications] = useState(false);
  function handleChangeCheckedNotifications(checkedNotifications) {
    setCheckedNotifications(checkedNotifications);
    const bodyObj = {
      important_notification: checkedNotifications,
    };
    axios
      .post("/admin/v1/profile/notification", bodyObj)
      .then((res) => {
        setToastMsg("Notifications Saved");
        toggleActive();
      })
      .catch((err) => console.log(err));
  }
  const rows = [
    [
      "Today at 12:19 pm	",
      "185.222.49.58	",
      "SPEEDBIT s.a.r.l",
      "Zahlé, 05, Lebanon",
    ],
    [
      "Today at 12:19 pm	",
      "185.222.49.58	",
      "SPEEDBIT s.a.r.l",
      "Zahlé, 05, Lebanon",
    ],
    [
      "Today at 12:19 pm	",
      "185.222.49.58	",
      "SPEEDBIT s.a.r.l",
      "Zahlé, 05, Lebanon",
    ],
    [
      "Today at 12:19 pm	",
      "185.222.49.58	",
      "SPEEDBIT s.a.r.l",
      "Zahlé, 05, Lebanon",
    ],
    [
      "Today at 12:19 pm	",
      "185.222.49.58	",
      "SPEEDBIT s.a.r.l",
      "Zahlé, 05, Lebanon",
    ],
    [
      "Today at 12:19 pm	",
      "185.222.49.58	",
      "SPEEDBIT s.a.r.l",
      "Zahlé, 05, Lebanon",
    ],
  ];
  const isFirstPage = true;
  const isLastPage = true;

  const [fieldRequired1, setFieldRequired1] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequired2, setFieldRequired2] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );

  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get("/admin/v1/profile")
      .then((result) => {
        setFirstNameValue(result.data.first_name);
        setLastNameValue(result.data.last_name);
        setEmailValue(result.data.email);
        setPhoneValue(result.data.personal_mobile_number);
      })
      .catch((err) => console.log(err));
  }
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const [toastMsg, setToastMsg] = useState("");

  const toastMarkup = active ? (
    <Toast content={toastMsg} onDismiss={toggleActive} />
  ) : null;

  return (
    <NavigationLayout>
      <Page>
        <Layout>
          <Layout.AnnotatedSection title="Account Information" description="">
            <Card title="User Profile">
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <FormLayout>
                      <TextField
                        value={firstNameValue}
                        label="First Name"
                        onChange={handleChangeFirstName}
                      />
                      {/* {fieldRequiredFN} */}
                    </FormLayout>
                    <FormLayout>
                      <TextField
                        value={lastNameValue}
                        label="Last Name"
                        onChange={handleChangeLastName}
                      />
                      {/* {fieldRequiredLN} */}
                    </FormLayout>
                  </FormLayout.Group>
                  <FormLayout.Group condensed>
                    <FormLayout>
                      <TextField value={emailValue} label="Email" />
                      {fieldRequiredE}
                    </FormLayout>
                    <FormLayout>
                      <TextField
                        value={phoneValue}
                        label="Phone"
                        onChange={handleChangePhone}
                        type="tel"
                      />
                      {fieldRequiredP}
                      {fieldRequired1}
                    </FormLayout>
                  </FormLayout.Group>
                  <Button onClick={handleSubmit}>Save</Button>
                </FormLayout>
              </Card.Section>

              <Card.Section title="Reset Password">
                <FormLayout>
                  <TextStyle>
                    Change the password you use to login to 4T.
                  </TextStyle>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <TextField
                        type="password"
                        value={newPasswordValue}
                        label="New Password"
                        onChange={handleChangeNewPassword}
                      />
                      <TextField
                        type="password"
                        value={confirmPasswordValue}
                        label="Confirm Password"
                        onChange={handleChangeConfirmPassword}
                      />
                    </FormLayout.Group>
                    {/* <Button>Reset Password</Button> */}
                  </FormLayout>
                  <Button onClick={handleResetPassword}>Reset Password</Button>
                  {errorPasswordTf}
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <PageActions>/</PageActions>
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );

  function handleChangeFirstName(firstNameValue) {
    setFirstNameValue(firstNameValue);
  }
  function handleChangeLastName(lastNameValue) {
    setLastNameValue(lastNameValue);
  }
  function handleChangeEmail(emailValue) {
    setEmailValue(emailValue);
  }
  function handleChangePhone(phoneValue) {
    setPhoneValue(phoneValue);
  }
  function handleChangeNewPassword(newPasswordValue) {
    setNewPasswordValue(newPasswordValue);
  }
  function handleChangeConfirmPassword(confirmPasswordValue) {
    setConfirmPasswordValue(confirmPasswordValue);
  }
  function handleSubmit() {
    // if (isNaN(phoneValue)) {
    // }
    if (
      !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ||
      (/^\d+$/.test(phoneValue) === false && phoneValue) ||
      (phoneValue && phoneValue.length != 8)
    ) {
      if (!emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
        setFieldRequiredE(
          <InlineError message="Invalid Email" fieldID="myFieldID" />
        );
      if (/^\d+$/.test(phoneValue) === false)
        setFieldRequiredP(
          <InlineError message="Phone must be numbers" fieldID="myFieldID" />
        );
      if (phoneValue.length != 8)
        setFieldRequired1(
          <InlineError message="Phone length must be 8" fieldID="myFieldID" />
        );
      // if (emailValue==='')
      // setFieldRequired1(<InlineError message='This field is required' fieldID="myFieldID" />)
    } else {
      const bodyObj = {
        first_name: firstNameValue,
        last_name: lastNameValue,
        email: emailValue,
        personal_mobile_number: phoneValue,
        bio: "",
      };
      axios
        .post("/admin/v1/profile", bodyObj)
        .then((res) => {
          setToastMsg("Your profile is updated");
          toggleActive();
        })
        .catch((err) => console.log(err));
    }
  }
  function handleResetPassword() {
    if (newPasswordValue != confirmPasswordValue) {
      setErrorPasswordTf(
        <InlineError
          message="Please confirm your password"
          fieldID="myFieldID"
        />
      );
      // errorPasswordTf = (<InlineError message="Empty Password" fieldID="myFieldID" />);
    } else {
      const bodyObj = {
        old_password: Cookies.get("4t-password"),
        new_password: newPasswordValue,
      };
      axios
        .post("/admin/v1/password/update", bodyObj)
        .then((result) => {
          setToastMsg("Your password is successfully changed");
          history.push("/");
          //toggleActive()
        })
        .catch((err) => console.log(err));
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
    axios
      .get("/admin/v1/audit?per_page=" + per_page + "&page=" + currentPage)
      .then((result) => {
        //setPrevPage(result.data.prev_page_url[result.data.prev_page_url.length - 1])
        //  setNextPage(result.data.next_page_url[result.data.next_page_url.length - 1])
        setItems(
          result.data.data.map((item) => [
            item.id,
            item.ip_address,
            item.created_at,
          ])
        );
        // setMyPageNb(myPageNb + 1)
      })
      .catch((err) => console.log(err));
  }
}
