import React, { useCallback, useState, useEffect } from "react";
import {
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Select,
  Card,
  Modal,
  PageActions,
} from "@shopify/polaris";
import axios from "../Assets/Libraries/axios";
import Cookies from "js-cookie";
import history from "../Assets/Libraries/history";
import { useParams } from "react-router-dom";

function EditKycForm(props) {
  let { id } = useParams();
  const [selectedResidenceCountry, setSelectedResidenceCountry] =
    useState("Afghanistan");
  const handleSelectChangeResidencCountry = useCallback(
    (value) => setSelectedResidenceCountry(value),
    []
  );
  const [optionsResidenceCountry, setOptionsResidenceCountry] = useState([]);
  const [cityValue, setCityValue] = useState("");
  const handleChangeCity = useCallback(
    (newValue) => setCityValue(newValue),
    []
  );
  const [optionsEstimatedAmount, setOptionsEstimatedAmount] = useState([
    { label: "Less than $25,000", value: "Less than $25,000" },
    { label: "$25,000 - $100,000", value: "$25,000 - $100,000" },
    { label: "$100,000 - $500,000", value: "$100,000 - $500,000" },
    { label: "Over $500,000", value: "Over $500,000" },
  ]);
  const [selectedEstimatedAmount, setSelectedEstimatedAmount] = useState("");
  const handleChangeSelectedEstimatedAmount = useCallback(
    (value) => setSelectedEstimatedAmount(value),
    []
  );
  const [addressValue, setAddressValue] = useState("");
  const handleChangeAddress = useCallback(
    (newValue) => setAddressValue(newValue),
    []
  );
  const [selectedNationality, setSelectedNationality] = useState("Afghanistan");
  const handleSelectChangeNationality = useCallback(
    (newValue) => setSelectedNationality(newValue),
    []
  );
  const [selectedIdentificationType, setSelectedIdentificationType] =
    useState("");
  const handleSelectChangeIdentificationType = useCallback(
    (value) => setSelectedIdentificationType(value),
    []
  );
  const [optionsIdentificationType, setOptionsIdentificationType] = useState(
    []
  );
  const [selectedUScitizen, setSelectedUScitizen] = useState("");
  const handleSelectChangeUScitizen = useCallback(
    (value) => setSelectedUScitizen(value),
    []
  );
  const optionsUScitizen = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState("");

  const handleSelectChangeEmploymentStatus = useCallback((value) => {
    setSelectedEmploymentStatus(value);
  }, []);
  const optionsEmploymentStatus = [
    { label: "Employed", value: "1" },
    { label: "Self Employed", value: "3" },
    { label: "Retired", value: "4" },
    { label: "Unemployed", value: "2" },
    { label: "Student", value: "5" },
  ];
  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState("");
  const handleSelectChangeAnnualIncome = useCallback(
    (value) => setSelectedAnnualIncome(value),
    []
  );
  const [optionsAnnualIncome, setOptionsAnnualIncome] = useState([]);
  const [selectedApproximateNetWorth, setSelectedApproximateNetWorth] =
    useState("");
  const handleSelectChangeApproximateNetWorth = useCallback(
    (value) => setSelectedApproximateNetWorth(value),
    []
  );
  const [optionsApproximateNetWorth, setOptionsApproximateNetWorth] = useState(
    []
  );
  const [selectedSourceWealth, setSelectedSourceWealth] = useState("");
  const handleSelectChangeSourceWealth = useCallback(
    (value) => setSelectedSourceWealth(value),
    []
  );
  const optionsSourceWealth = [
    { label: "Employment", value: "1" },
    { label: "Investment", value: "2" },
    { label: "Inheritance", value: "3" },
    { label: "Other", value: "4" },
    { label: "Pension", value: "28" },
  ];
  const [selectedTradingExperience, setSelectedTradingExperience] =
    useState("");
  const handleSelectChangeTradingExperience = useCallback(
    (value) => setSelectedTradingExperience(value),
    []
  );
  const [optionsTradingExperience, setOptionsTradingExperience] = useState("");
  const [
    selectedHowOftenTradingExperience,
    setSelectedHowOftenTradingExperience,
  ] = useState("");
  const handleSelectChangeHowOftenTradingExperience = useCallback(
    (value) => setSelectedHowOftenTradingExperience(value),
    []
  );
  const optionsHowOftenTradingExperience = [
    { label: "Frequently (Daily)", value: "Frequently (Daily)" },
    { label: "Regularly (Weekly)", value: "Regularly (Weekly)" },
    {
      label: "Occasionally (a few times a year)",
      value: "Occasionally (a few times a year)",
    },
  ];
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const handleSelectChangeCurrency = useCallback(
    (value) => setSelectedCurrency(value),
    []
  );
  const optionsCurrency = [
    { label: "USD", value: "3" },
    { label: "EU", value: "5" },
  ];
  const [selectedMetatrader, setSelectedMetatrader] = useState("");
  const handleSelectChangeMetatrader = useCallback(
    (value) => setSelectedMetatrader(value),
    []
  );
  const optionsMetatrader = [
    { label: "MetaTrader 4", value: "metatrader4" },
    { label: "MetaTrader 5", value: "metatrader5" },
  ];
  const [selectedFuturesAndOptions, setSelectedFuturesAndOptions] =
    useState("");
  const handleSelectChangeFuturesAndOptions = useCallback(
    (value) => setSelectedFuturesAndOptions(value),
    []
  );
  const optionsFuturesAndOptions = [
    { label: "Never", value: "never" },
    { label: "1 to 15 trades", value: "1 to 15 trades" },
    { label: "15 to 40 trades", value: "15 to 40 trades" },
    { label: "More than 40 trades", value: "More than 40 trades" },
  ];
  const [selectedDerivatives, setSelectedDerivatives] = useState("");
  const handleSelectChangeDerivatives = useCallback(
    (value) => setSelectedDerivatives(value),
    []
  );
  const optionsDerivatives = [
    { label: "Never", value: "never" },
    { label: "1 to 15 trades", value: "1 to 15 trades" },
    { label: "15 to 40 trades", value: "15 to 40 trades" },
    { label: "More than 40 trades", value: "More than 40 trades" },
  ];
  const [selectedAccountPurpose, setSelectedAccountPurpose] = useState("");
  const handleSelectChangeAccountPurpose = useCallback(
    (value) => setSelectedAccountPurpose(value),
    []
  );
  const optionsAccountPurpose = [
    { label: "Capital Growth", value: "3" },
    { label: "Speculation", value: "4" },
    { label: "Income", value: "5" },
    { label: "Hedging", value: "6" },
    { label: "Other", value: "7" },
  ];
  const [selectedRelevantExperience, setSelectedRelevantExperience] =
    useState("");
  const handleSelectChangeRelevantExperience = useCallback(
    (value) => setSelectedRelevantExperience(value),
    []
  );
  const optionsRelevantExperience = [
    {
      label: "Yes, over three years of experience",
      value: "Yes, over three years of experience",
    },
    {
      label: "Yes, under three years of experience",
      value: "Yes, under three years of experience",
    },
    { label: "No", value: "No" },
  ];

  const [indentificationNumber, setIdentificationNumberValue] = useState("");
  const handleChangeIdentificationNumber = useCallback(
    (newValue) => setIdentificationNumberValue(newValue),
    []
  );
  const [governmentNumberValue, setGovernmentNumberValue] = useState("");
  const handleChangeGovernmentNumber = useCallback(
    (newValue) => setGovernmentNumberValue(newValue),
    []
  );
  const [positionTitleValue, setPositionTitleValue] = useState("");
  const handleChangePositionTitle = useCallback(
    (newValue) => setPositionTitleValue(newValue),
    []
  );
  const [employerName, setEmployerName] = useState("");
  const handleChangeEmployerName = useCallback(
    (newValue) => setEmployerName(newValue),
    []
  );
  const [employerAddress, setEmployerAddress] = useState("");
  const handleChangeEmployerAddress = useCallback(
    (newValue) => setEmployerAddress(newValue),
    []
  );
  const [employerTelephone, setEmployerTelephone] = useState("");
  const handleChangeEmployerTelephone = useCallback(
    (newValue) => setEmployerTelephone(newValue),
    []
  );
  const [previousPositionTitleValue, setPreviousPositionTitleValue] =
    useState("");
  const handleChangePreviousPositionTitle = useCallback(
    (newValue) => setPreviousPositionTitleValue(newValue),
    []
  );
  const [specifyWealthSource, setSpecifyWealthSource] = useState("");
  const handleChangeSpecifyWealthSource = useCallback(
    (newValue) => setSpecifyWealthSource(newValue),
    []
  );
  const [selectedAcademic, setSelectedAcademic] = useState("");
  const handleSelectChangeAcademic = useCallback(
    (value) => setSelectedAcademic(value),
    []
  );
  const optionsAcademic = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const [selectedBeneficiaryOwner, setSelectedBeneficiaryOwner] = useState("");
  const handleSelectChangeBeneficiaryOwner = useCallback(
    (value) => setSelectedBeneficiaryOwner(value),
    []
  );
  const optionsBeneficiaryOwner = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const [selectedIsDirector, setSelectedIsDirector] = useState("");
  const handleSelectChangeIsDirector = useCallback(
    (value) => setSelectedIsDirector(value),
    []
  );
  const optionsIsDirector = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const [valueSpecifyAccountPurpose, setSpecifyAccountPurpose] = useState("");
  const handleChangeSpecifyAccountPurpose = useCallback(
    (newValue) => setSpecifyAccountPurpose(newValue),
    []
  );
  const [specifyQualificationValue, setSpecifyQualificationValue] =
    useState("");
  const handleChangeSpecifyQualification = useCallback(
    (newValue) => setSpecifyQualificationValue(newValue),
    []
  );

  const [responseKycDetails, setResponseKycDetails] = useState([]);
  const [companyNameValue, setCompanyName] = useState("");
  const handleChangeCompanyName = useCallback(
    (newValue) => setCompanyName(newValue),
    []
  );
  const [selectedPEP, setSelectedPEP] = useState("");
  const handleSelectChangePEP = useCallback(
    (value) => setSelectedPEP(value),
    []
  );
  const optionsPEP = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const [popupActive, setPopupActive] = useState(false);
  useEffect(() => {
    setPopupActive(true);
    Promise.all([
      axios.get(`admin/v1/currentdesk/countries`),
      axios.get(`admin/v1/leads/${id}/kyc-form`),
    ]).then(function (responses) {
      setPopupActive(false);
      const responseCountries = responses[0].data.data;
      const responseKycForm = responses[1].data.data.form;
      setResponseKycDetails(responses[1].data.data.form);
      setOptionsResidenceCountry(
        responseCountries.map((item) => {
          return {
            label: String(item.Value.en),
            value: String(item.Id),
          };
        })
      );
      const newArr = [...responseCountries];
      newArr.splice(
        newArr.findIndex((item) => String(item.Value) === "Israel"),
        1
      );
      setOptionsResidenceCountry(
        newArr.map((item) => {
          return {
            label: String(item.Value.en),
            value: String(item.Id),
          };
        })
      );
      responseKycForm.regulators === "lebanon"
        ? setOptionsTradingExperience([
            { label: "None", value: "30" },
            { label: "1 - 3 Years", value: "31" },
            { label: "3 - 5 Years", value: "32" },
            { label: "5+ Years", value: "33" },
          ])
        : setOptionsTradingExperience([
            { label: "None", value: "34" },
            { label: "1 - 3 Years", value: "35" },
            { label: "3 - 5 Years", value: "36" },
            { label: "5+ Years", value: "37" },
          ]);
      responseKycForm.regulators === "lebanon"
        ? setOptionsIdentificationType([
            { label: "Passport", value: "203" },
            // { label: "Drivers License", value: "204" },
            { label: "Government ID", value: "205" },
          ])
        : setOptionsIdentificationType([
            { label: "Passport", value: "212" },
            // { label: "Drivers License", value: "213" },
            { label: "Government ID", value: "214" },
          ]);
      responseKycForm.regulators === "lebanon"
        ? setOptionsAnnualIncome([
            { label: "Less than $15,000", value: "305" },
            { label: "$15,000-$50,000", value: "306" },
            { label: "$50,001-$100,000", value: "307" },
            { label: "Over $100,000", value: "308" },
          ])
        : setOptionsAnnualIncome([
            { label: "Less than $15,000", value: "317" },
            { label: "$15,000-$50,000", value: "318" },
            { label: "$50,001-$100,000", value: "319" },
            { label: "Over $100,000", value: "320" },
          ]);
      responseKycForm.regulators === "lebanon"
        ? setOptionsApproximateNetWorth([
            { label: "Less than $25,000", value: "323" },
            { label: "$25,000 - $100,000", value: "324" },
            { label: "$100,001 - $500,000", value: "325" },
            { label: "Over $500,000", value: "326" },
          ])
        : setOptionsApproximateNetWorth([
            { label: "Less than $25,000", value: "335" },
            { label: "$25,000-$100,000", value: "336" },
            { label: "$100,001-500,000", value: "337" },
            { label: "Over $500,000", value: "338" },
          ]);
      responseKycForm.residential_address &&
        responseKycForm.residential_address.country_of_residence &&
        setSelectedResidenceCountry(
          responseKycForm.residential_address.country_of_residence
        );
      responseKycForm.residential_address &&
        responseKycForm.residential_address.city &&
        setCityValue(responseKycForm.residential_address.city);
      responseKycForm.residential_address &&
        responseKycForm.residential_address.address &&
        setAddressValue(responseKycForm.residential_address.address);
      responseKycForm.residential_address &&
        responseKycForm.residential_address.nationality &&
        setSelectedNationality(responseKycForm.residential_address.nationality);
      responseKycForm.residential_address &&
        responseKycForm.residential_address.identification_type &&
        setSelectedIdentificationType(
          String(responseKycForm.residential_address.identification_type)
        );
      responseKycForm.residential_address &&
        responseKycForm.residential_address.us_citizen &&
        setSelectedUScitizen(
          String(responseKycForm.residential_address.us_citizen)
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.employment_status &&
        setSelectedEmploymentStatus(
          String(responseKycForm.financial_background.employment_status)
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.annual_income &&
        setSelectedAnnualIncome(
          String(responseKycForm.financial_background.annual_income)
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.net_worth &&
        setSelectedApproximateNetWorth(
          String(responseKycForm.financial_background.net_worth)
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.source_of_wealth &&
        setSelectedSourceWealth(
          String(responseKycForm.financial_background.source_of_wealth)
        );

      responseKycForm.regulators === "seychelles" &&
        responseKycForm?.financial_background?.company_name &&
        setCompanyName(responseKycForm.financial_background.company_name);
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.id &&
        setSelectedTradingExperience(
          String(responseKycForm.trading_experience.id)
        );
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.how_often_do_you_expext_to_trade &&
        setSelectedHowOftenTradingExperience(
          String(
            responseKycForm.trading_experience.how_often_do_you_expext_to_trade
          )
        );
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.currency !== null &&
        setSelectedCurrency(
          String(responseKycForm.trading_experience.currency)
        );
      console.log(
        "responseKycForm.trading_experience.type=",
        responseKycForm.trading_experience.type
      );
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.type &&
        setSelectedMetatrader(responseKycForm.trading_experience.type);
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.futures &&
        setSelectedFuturesAndOptions(
          String(responseKycForm.trading_experience.futures)
        );
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.cfd_foreign_exchange &&
        setSelectedDerivatives(
          String(responseKycForm.trading_experience.cfd_foreign_exchange)
        );
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.account_purpose &&
        setSelectedAccountPurpose(
          String(responseKycForm.trading_experience.account_purpose)
        );
      responseKycForm.professional_experience &&
        responseKycForm.professional_experience.relevant_experience &&
        setSelectedRelevantExperience(
          responseKycForm.professional_experience.relevant_experience
        );
      responseKycForm.residential_address &&
        responseKycForm.residential_address.identification_number &&
        setIdentificationNumberValue(
          responseKycForm.residential_address.identification_number
        );
      responseKycForm.professional_experience.academic &&
        responseKycForm.professional_experience.academic &&
        setSelectedAcademic(
          String(responseKycForm.professional_experience.academic)
        );
      responseKycForm.professional_experience &&
        responseKycForm.professional_experience.beneficiary &&
        setSelectedBeneficiaryOwner(
          String(responseKycForm.professional_experience.beneficiary)
        );
      responseKycForm.professional_experience &&
        responseKycForm.professional_experience.director &&
        setSelectedIsDirector(
          String(responseKycForm.professional_experience.director)
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.position_title &&
        setPositionTitleValue(
          responseKycForm.financial_background.position_title
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.employer_name &&
        setEmployerName(responseKycForm.financial_background.employer_name);
      responseKycForm.financial_background &&
        responseKycForm.financial_background.employer_address &&
        setEmployerAddress(
          responseKycForm.financial_background.employer_address
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.employer_telephone &&
        setEmployerTelephone(
          responseKycForm.financial_background.employer_telephone
        );
      responseKycForm.financial_background &&
        responseKycForm.financial_background.previous_position_title &&
        setPreviousPositionTitleValue(
          responseKycForm.financial_background.previous_position_title
        );

      responseKycForm.financial_background &&
        responseKycForm.financial_background.other_source_of_wealth &&
        setSpecifyWealthSource(
          responseKycForm.financial_background.other_source_of_wealth
        );
      responseKycForm.trading_experience &&
        responseKycForm.trading_experience.other_account_purpose &&
        setSpecifyAccountPurpose(
          responseKycForm.trading_experience.other_account_purpose
        );
      responseKycForm.professional_experience &&
        responseKycForm.professional_experience.qualifications &&
        setSpecifyQualificationValue(
          responseKycForm.professional_experience.qualifications
        );
      responseKycForm.financial_background?.estimate_deposite_size &&
        setSelectedEstimatedAmount(
          responseKycForm.financial_background?.estimate_deposite_size
        );
      responseKycForm?.politically_exposed_person &&
        setSelectedPEP(
          responseKycForm.politically_exposed_person.toLowerCase()
        );
    });
  }, []);
  const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const switchParam = (param) => {
    switch (param) {
      case "leads":
        return "leads";
      case "promofix":
        return "promofix-leads";
      case "africa":
        return "africa-leads";
      case "users":
        return "users";

      default:
      // code block
    }
  };
  return (
    <Page
      title="Edit KYC Form"
      breadcrumbs={[
        {
          content: "Lead Details",
          url: `/${switchParam(props.type)}/${id}`,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">Home Address</TextStyle>
          <Select
            options={optionsResidenceCountry}
            onChange={handleSelectChangeResidencCountry}
            value={selectedResidenceCountry}
            placeholder="Select country of residence"
            label="Country of residence"
          />
          <TextField
            label="City"
            value={cityValue}
            onChange={handleChangeCity}
          />
          <TextField
            label="Address"
            value={addressValue}
            onChange={handleChangeAddress}
          />
          <TextStyle variation="strong">Nationality</TextStyle>
          <Select
            options={optionsResidenceCountry}
            onChange={handleSelectChangeNationality}
            value={selectedNationality}
            placeholder="Select nationality"
          />
          <TextStyle variation="strong">Identification Type</TextStyle>
          <Select
            options={optionsIdentificationType}
            onChange={handleSelectChangeIdentificationType}
            value={selectedIdentificationType}
            placeholder="Select Identification Type"
          />

          {(selectedIdentificationType === "203" ||
            selectedIdentificationType === "212" ||
            selectedIdentificationType === "205" ||
            selectedIdentificationType === "214") && (
            <TextField
              label="Identification Number"
              value={indentificationNumber}
              onChange={handleChangeIdentificationNumber}
            />
          )}

          <TextStyle variation="strong">
            Are you a US citizen or resident?
          </TextStyle>
          <Select
            options={optionsUScitizen}
            onChange={handleSelectChangeUScitizen}
            value={selectedUScitizen}
            placeholder="Please select"
          />

          <TextStyle variation="strong">Employment Status</TextStyle>
          <Select
            options={optionsEmploymentStatus}
            onChange={handleSelectChangeEmploymentStatus}
            value={selectedEmploymentStatus}
            placeholder="Select Employment Status"
          />

          {(selectedEmploymentStatus === "1" ||
            selectedEmploymentStatus === "3") && (
            <div style={{ marginTop: "30px" }}>
              <FormLayout>
                <TextField
                  label="Company Name"
                  value={companyNameValue}
                  onChange={handleChangeCompanyName}
                />
                {responseKycDetails.regulators === "seychelles" && (
                  <TextField
                    label="Occupation / Position Title"
                    value={positionTitleValue}
                    onChange={handleChangePositionTitle}
                  />
                )}

                <TextField
                  label="Employer's  Name"
                  value={employerName}
                  onChange={handleChangeEmployerName}
                />
                <TextField
                  label="Employer's  Address"
                  value={employerAddress}
                  onChange={handleChangeEmployerAddress}
                />
                <TextField
                  label="Employer's telephone "
                  value={employerTelephone}
                  onChange={handleChangeEmployerTelephone}
                />
              </FormLayout>
            </div>
          )}
          {selectedEmploymentStatus === "2" && (
            <TextField
              label="Previous Occupation / Position Title"
              value={previousPositionTitleValue}
              onChange={handleChangePreviousPositionTitle}
            />
          )}
          <Select
            options={optionsAnnualIncome}
            onChange={handleSelectChangeAnnualIncome}
            value={selectedAnnualIncome}
            placeholder="Select Annual Income"
            label="Annual Income"
          />
          <Select
            options={optionsApproximateNetWorth}
            onChange={handleSelectChangeApproximateNetWorth}
            value={selectedApproximateNetWorth}
            placeholder="Select Approximate net worth (excluding primary residence value)"
            label="Approximate net worth (excluding primary residence value)"
          />
          <Select
            options={optionsSourceWealth}
            onChange={handleSelectChangeSourceWealth}
            value={selectedSourceWealth}
            placeholder="Select Source of Wealth"
            label="Source of Wealth"
          />
          {selectedSourceWealth === "4" && (
            <TextField
              label="Please sepcify source of wealth"
              value={specifyWealthSource}
              onChange={handleChangeSpecifyWealthSource}
            />
          )}

          {selectedEstimatedAmount && (
            <Select
              options={optionsEstimatedAmount}
              onChange={handleChangeSelectedEstimatedAmount}
              value={selectedEstimatedAmount}
              placeholder="Please Select"
              label="What is the estimated amount you plan to deposit during the first 12 months"
            />
            // <TextField
            //   label="What is the estimated amount you plan to deposit during the first 12 months"
            //   value={estimatedAmountDeposit}
            //   onChange={handleChangeEstimatedAmountDeposit}
            // />
          )}

          <TextStyle variation="strong">Trading Experience</TextStyle>
          <Select
            options={optionsTradingExperience}
            onChange={handleSelectChangeTradingExperience}
            value={selectedTradingExperience}
            placeholder="Select your Experience"
          />
          <Select
            options={optionsHowOftenTradingExperience}
            onChange={handleSelectChangeHowOftenTradingExperience}
            value={selectedHowOftenTradingExperience}
            placeholder="How often do you expect to trade?"
            label="How often do you expect to trade?"
          />
          <Select
            options={optionsCurrency}
            onChange={handleSelectChangeCurrency}
            value={selectedCurrency}
            placeholder="Please select"
            label="Choose currency"
          />
          <Select
            options={optionsMetatrader}
            onChange={handleSelectChangeMetatrader}
            value={selectedMetatrader}
            placeholder="Please select"
            label="Choose which Metatrader Platform you prefer to use "
          />
          <TextStyle variation="strong">
            Over the past three years, to what extent have you traded the
            following financial instruments where you have made your own trading
            decisions:
          </TextStyle>
          <TextStyle variation="strong">Futures and Options</TextStyle>
          <Select
            options={optionsFuturesAndOptions}
            onChange={handleSelectChangeFuturesAndOptions}
            value={selectedFuturesAndOptions}
            placeholder="Futures and Options"
          />
          <Select
            options={optionsDerivatives}
            onChange={handleSelectChangeDerivatives}
            value={selectedDerivatives}
            placeholder="CFDs or Foreign Exchange Derivatives"
            label="CFDs or Foreign Exchange Derivatives"
          />
          <TextStyle variation="strong">
            What is the main purpose of the account?
          </TextStyle>
          <Select
            options={optionsAccountPurpose}
            onChange={handleSelectChangeAccountPurpose}
            value={selectedAccountPurpose}
            placeholder="Please select"
          />
          {selectedAccountPurpose === "7" && (
            <TextField
              label="Please specify other account purpose "
              value={valueSpecifyAccountPurpose}
              onChange={handleChangeSpecifyAccountPurpose}
            />
          )}

          <TextStyle variation="strong">
            Do you have relevant professional experience (that has provided you
            with a good understanding of trading in Spot FX / CFDs / Options or
            Futures) ?
          </TextStyle>

          <Select
            options={optionsRelevantExperience}
            onChange={handleSelectChangeRelevantExperience}
            value={selectedRelevantExperience}
            placeholder="Please select"
          />

          <TextStyle variation="strong">
            Do you have any relevant professional or academic qualification that
            would provide you with a good understanding of the risks involved in
            trading leveraged and derivative products (e.g. CFA) ?
          </TextStyle>
          <Select
            options={optionsAcademic}
            onChange={handleSelectChangeAcademic}
            value={selectedAcademic}
            placeholder="Please select"
          />
          {selectedAcademic === "yes" && (
            <TextField
              label="Please specify qualification "
              value={specifyQualificationValue}
              onChange={handleChangeSpecifyQualification}
            />
          )}
          <TextStyle variation="strong">
            Are you the beneficiary owner of the account?
          </TextStyle>
          <Select
            options={optionsBeneficiaryOwner}
            onChange={handleSelectChangeBeneficiaryOwner}
            value={selectedBeneficiaryOwner}
            placeholder="Please select"
          />
          <TextStyle variation="strong">
            Are you a director or officer of a publicly listed company?
          </TextStyle>
          <Select
            options={optionsIsDirector}
            onChange={handleSelectChangeIsDirector}
            value={selectedIsDirector}
            placeholder="Please select"
          />
          <TextStyle variation="strong">
            Are you a politically exposed person?
          </TextStyle>
          {responseKycDetails.regulators == "seychelles" && (
            <Select
              options={optionsPEP}
              onChange={handleSelectChangePEP}
              value={selectedPEP}
              placeholder="Please select"
            />
          )}
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );

  function handleSave() {
    setPopupActive(true);

    const bodyObj = {
      form: {
        politically_exposed_person: selectedPEP,
        financial_background: {
          annual_income: selectedAnnualIncome,
          employer_address: employerAddress,
          employer_name: employerName,
          employer_telephone: employerTelephone,
          employment_status: selectedEmploymentStatus,
          net_worth: selectedApproximateNetWorth,
          other_source_of_wealth: specifyWealthSource,
          position_title: positionTitleValue,
          previous_position_title: previousPositionTitleValue,
          source_of_wealth: selectedSourceWealth,
          estimate_deposite_size: selectedEstimatedAmount,
          company_name: companyNameValue,
        },
        professional_experience: {
          academic: selectedAcademic,
          beneficiary: selectedBeneficiaryOwner,
          director: selectedIsDirector,
          qualifications: specifyQualificationValue,
          relevant_experience: selectedRelevantExperience,
        },
        regulators: responseKycDetails.regulators,
        residential_address: {
          address: addressValue,
          city: cityValue,
          country_of_residence: selectedResidenceCountry,
          identification_number: indentificationNumber,
          identification_type: selectedIdentificationType,
          nationality: selectedNationality,
          us_citizen: selectedUScitizen,
        },
        trading_experience: {
          account_purpose: selectedAccountPurpose,
          cfd_foreign_exchange: selectedDerivatives,
          futures: selectedFuturesAndOptions,
          how_often_do_you_expext_to_trade: selectedHowOftenTradingExperience,
          id: selectedTradingExperience,
          other_account_purpose: valueSpecifyAccountPurpose,
          currency: selectedCurrency,
          type: selectedMetatrader,
        },
        understanding: responseKycDetails.understanding,
      },
    };

    axios
      .post(`admin/v1/leads/${id}/kyc-form`, bodyObj)
      .then((res) => {
        setPopupActive(false);
        history.push(`/${switchParam(props.type)}/${id}`);
      })
      .catch((err) => setPopupActive(false));
  }
}
export default EditKycForm;
