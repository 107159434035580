import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Thumbnail,
  Caption,
  InlineError,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Select,
  Card,
  PageActions,
  Modal,
} from "@shopify/polaris";
import axios from "../Assets/Libraries/axios";
import Cookies from "js-cookie";
import history from "../Assets/Libraries/history";
import { useParams } from "react-router-dom";

function Edit() {
  let { id } = useParams();
  var json_str = Cookies.get("clickedItem");
  var cookieResult = JSON.parse(json_str);
  const [valueFirstName, setvalueFirstName] = useState(cookieResult.first_name);
  const [valueLastName, setvalueLastName] = useState(cookieResult.last_name);
  const [valueEmail, setValueEmail] = useState(cookieResult.email);
  const [valuePassword, setValuePassword] = useState("");
  const [valueConfirmPassword, setValueConfirmPassword] = useState("");
  const [selected, setSelected] = useState(String(cookieResult.roleId));
  const [checked, setChecked] = useState(cookieResult.active);
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  let email = cookieResult.email;
  const [popupActive, setPopupActive] = useState(false);

  // const [options, setOptions] = useState([]);
  //let arrayChoices = ["Editor", "Writer", "Moderator", "Admin", "Super-Admin"];
  const options = [
    { label: "Admin", value: "4" },
    { label: "Lead Admin", value: "6" },
    { label: "Promofix Lead", value: "7" },

    { label: "Editor", value: "1" },
  ];
  let arrayOfObjects = [];

  const [fieldRequiredFN, setFieldRequiredFN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredLN, setFieldRequiredLN] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredE, setFieldRequiredE] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredP, setFieldRequiredP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  const [fieldRequiredCP, setFieldRequiredCP] = useState(
    <InlineError message="" fieldID="myFieldID" />
  );
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    //setValuePassword("");
  }
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState(cookieResult.imageUrl);
  let imageType = "";
  const [imageKey, setImageKey] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      const form_data = new FormData();
      form_data.append("image_name", files[files.length - 1].name);

      axios
        .post("/admin/v1/Images/s3", form_data)
        .then((res) => {
          setImageKey(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          let form_data = new FormData();
          //form_data.append("image_name", files[files.length - 1].name);
          //form_data.append("image", files[files.length - 1]);
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [files]
  );
  return (
    <Page
      title="Edit User"
      breadcrumbs={[{ content: "List Of Users", url: "/adminusers" }]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="strong">First Name</TextStyle>
          <TextField value={valueFirstName} onChange={handleChangeFirstName} />
          {fieldRequiredFN}
          <TextStyle variation="strong">Last Name</TextStyle>
          <TextField value={valueLastName} onChange={handleChangeLastName} />
          {fieldRequiredLN}

          <TextStyle variation="strong">Email</TextStyle>
          <TextField value={valueEmail} onChange={handleChangeEmail} />
          {fieldRequiredE}
          <TextStyle variation="strong">Image</TextStyle>
          <Thumbnail size="large" source={imagePreview} />
          <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
            {uploadedFiles}
            {fileUpload}
          </DropZone>
          <TextStyle variation="strong">Select Role</TextStyle>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={selected}
          />
          <Button onClick={handleChangePasswordValue}>Change Password</Button>
          <div
            id="divChangePassword"
            style={{ display: "none", marginTop: "30px" }}
          >
            <FormLayout>
              <TextStyle variation="strong">Password</TextStyle>
              <TextField
                value={valuePassword}
                onChange={handleChangePassword}
              />
              {fieldRequiredP}
              <TextStyle variation="strong">Confirm Password</TextStyle>
              <TextField
                value={valueConfirmPassword}
                onChange={handleChangeConfirmPassword}
              />
              {fieldRequiredCP}
            </FormLayout>
          </div>
          <Checkbox
            label="Is Active"
            checked={checked}
            onChange={handleChange}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
        secondaryActions={[
          {
            content: "Delete",
            onClick: () =>
              axios
                .delete("admin/v1/management/users/" + id + "/delete")
                .then((result) => {
                  history.push("/adminusers");
                })
                .catch((err) => console.log(err)),
          },
        ]}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleChangeFirstName(valueFirstName) {
    setvalueFirstName(valueFirstName);
    if (valueFirstName !== "") {
      setFieldRequiredFN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeLastName(valueLastName) {
    setvalueLastName(valueLastName);
    if (valueLastName !== "") {
      setFieldRequiredLN(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeEmail(valueEmail) {
    setValueEmail(valueEmail);
    if (valueEmail !== "") {
      setFieldRequiredE(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangePassword(valuePassword) {
    setValuePassword(valuePassword);
    if (valuePassword !== "") {
      setFieldRequiredP(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangeConfirmPassword(valueConfirmPassword) {
    setValueConfirmPassword(valueConfirmPassword);
    if (valueConfirmPassword !== "") {
      setFieldRequiredCP(<InlineError message="" fieldID="myFieldID" />);
    }
  }
  function handleChangePasswordValue() {
    document.getElementById("divChangePassword").style.display = "block";
  }
  function handleSave() {
    if (
      valueFirstName === "" ||
      valueLastName === "" ||
      valueEmail === "" ||
      (valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      (valuePassword !== "" &&
        valueConfirmPassword !== "" &&
        valuePassword !== valueConfirmPassword)
    ) {
      if (valueFirstName === "") {
        setFieldRequiredFN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }
      if (valueLastName === "") {
        setFieldRequiredLN(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }
      if (valueEmail === "") {
        setFieldRequiredE(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }
      if (valuePassword === "") {
        setFieldRequiredP(
          <InlineError message="This field is required" fieldID="myFieldID" />
        );
      }

      if (
        valueEmail !== "" &&
        !valueEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        setFieldRequiredE(
          <InlineError
            message="Please enter a valid email address"
            fieldID="myFieldID"
          />
        );
      }
      if (valuePassword.length < 6) {
        setFieldRequiredP(
          <InlineError
            message="Password must be at least 6 digit"
            fieldID="myFieldID"
          />
        );
      }
      if (valuePassword !== valueConfirmPassword) {
        setFieldRequiredCP(
          <InlineError
            message="Passwords are not matching"
            fieldID="myFieldID"
          />
        );
        if (valuePassword !== valueConfirmPassword) {
          setFieldRequiredCP(
            <InlineError
              message="Passwords are not matching"
              fieldID="myFieldID"
            />
          );
        }
      }
    } else {
      const bodyObj = {
        first_name: valueFirstName,
        last_name: valueLastName,
        email: valueEmail,
        password: valuePassword,
        active: checked,
        role: selected,
        "confirm-password": valueConfirmPassword,
        profile_image: imageKey,
      };
      if (valuePassword === "") {
        delete bodyObj.password;
        delete bodyObj["confirm-password"];
      }
      if (imageKey === "") {
        delete bodyObj.profile_image;
      }
      axios
        .post("admin/v1/management/users/" + id + "/update", bodyObj)
        .then((res) => {
          history.push("/adminusers");
        })
        .catch((err) => handleError(err));
    }
  }
  function handleError(err) {
    setFieldRequiredE(
      <InlineError message="This email is already used" fieldID="myFieldID" />
    );
  }
}
export default Edit;
