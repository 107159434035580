import React, { useCallback, useEffect } from "react";
import "@shopify/polaris/dist/styles.css";
import { Switch, Route, Router } from "react-router-dom";
import history from "./Assets/Libraries/history";
import Login from "./Authentication/Login";
import { Offline, Online } from "react-detect-offline";
import { AppProvider } from "@shopify/polaris";
import ForgotPassword from "./Authentication/ForgotPassword";
import ResetPassword from "./Authentication/ResetPassword";
import Settings from "./Settings";
import MainAdminUsers from "./AdminUsers/Main";
import MainPages from "./Pages/Main";
import Profile from "./Profile";
import "./Assets/Styles/react-tagsinput.css";
import "./Assets/Styles/Globals.css";
import NewsletterSubscribersList from "./NewsletterSubscribers/List";
import Campaign from "./Campaign/Main";
import MainAfricaLeads from "./Leads/MainAfricaLeads";
import MainUsers from "./Leads/Main";
import MainLeads from "./Leads/MainLeads";
import MainPromofixLeads from "./Leads/MainPromofixLeads";
import Prices from "./LiveMarketPrices/Main";

function App() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/profile" component={Profile} />
          <Route path="/users" component={MainUsers} />
          <Route path="/africa-leads" component={MainAfricaLeads} />
          <Route path="/promofix-leads" component={MainPromofixLeads} />
          <Route path="/leads" component={MainLeads} />
          <Route path="/campaign" component={Campaign} />
          <Route
            path="/newsletter-subscribers"
            component={NewsletterSubscribersList}
          />
          <Route path="/pages" component={MainPages} />
          <Route path="/settings" component={Settings} />
          <Route path="/adminusers" component={MainAdminUsers} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/password/reset" component={ResetPassword} />
          <Route path="/live-market-prices" component={Prices} />
        </Switch>
      </Router>
      {/* <div>
        <Online></Online>
        <Offline>
          {" "}
          <div>
            <Frame>{toastMarkup}</Frame>
          </div>
        </Offline>
      </div> */}
    </AppProvider>
  );
}
export default App;
