import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Libraries/history";
import List from "./List";
import Add from "./Add";
import NavigationLayout from "../../Components/NavigationLayout";
import Edit from "./Edit";

const Symbols = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/live-market-prices/:id/symbols" component={List} />
        <Route path="/live-market-prices/:id/symbols/new" component={Add} />
        <Route
          path="/live-market-prices/:priceId/symbols/:symbolId"
          component={Edit}
        />
      </Switch>
    </Router>
  );
};
export default Symbols;
