import React, { useState, useEffect, useCallback } from "react";
import {
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Card,
  Modal,
  PageActions,
  Select,
} from "@shopify/polaris";
import axios from "../Assets/Libraries/axios";
import Cookies from "js-cookie";
import history from "../Assets/Libraries/history";
import { useParams } from "react-router-dom";

function EditProfile(props) {
  let { id } = useParams();
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [dateOfBirthValue, setdateOfBirthValue] = useState("");
  const [creationDate, setcreationDate] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [clinicNameError, setClinicNameError] = useState("");
  const [cityError, setCityError] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const handleSelectChangeTitle = useCallback(
    (value) => setSelectedTitle(value),
    []
  );
  const [tradingType, setTradingType] = useState("");

  const handleSelectChangeTradingType = useCallback(
    (value) => setTradingType(value),
    []
  );
  const titleOptions = [
    { label: "Mr.", value: "1" },
    { label: "Mrs.", value: "2" },
    { label: "Ms.", value: "4" },
    { label: "Dr.", value: "5" },
    { label: "Prof.", value: "6" },
  ];
  useEffect(() => {
    axios
      .get(`admin/v1/leads/${id}/kyc-form`)
      .then((result) => {
        result.data.profile.first_name &&
          setFirstNameValue(result.data.profile.first_name);
        result.data.profile.last_name &&
          setLastNameValue(result.data.profile.last_name);
        result.data.profile.email && setEmailValue(result.data.profile.email);
        result.data.profile.personal_mobile_number &&
          setPhoneValue(result.data.profile.personal_mobile_number);
        result.data.profile.date_of_birth &&
          setdateOfBirthValue(result.data.profile.date_of_birth);
        result.data.profile.title_id &&
          setSelectedTitle(String(result.data.profile.title_id));
        result.data.profile.trading_type &&
          setTradingType(String(result.data.profile.trading_type));
      })
      .catch((err) => console.log(err));
  }, []);
  const switchParam = (param) => {
    switch (param) {
      case "leads":
        return "leads";
      case "promofix":
        return "promofix-leads";
      case "africa":
        return "africa-leads";
      case "users":
        return "users";

      default:
      // code block
    }
  };
  return (
    <Page
      title="Edit Profile"
      breadcrumbs={[
        {
          content: "Lead Details",
          url: `/${switchParam(props.type)}/${id}`,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">First Name</TextStyle>
              <TextField
                value={firstNameValue}
                onChange={handleChangeFirstName}
                error={firstNameError}
                ariaExpanded
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Last Name</TextStyle>
              <TextField
                value={lastNameValue}
                onChange={handleChangeLastName}
                error={lastNameError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Email</TextStyle>
              <TextField
                value={emailValue}
                onChange={handleChangeEmail}
                error={emailError}
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Phone Number</TextStyle>
              <TextField
                value={phoneValue}
                onChange={handleChangePhone}
                error={phoneError}
              />
            </FormLayout>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Date Of Birth</TextStyle>
              <TextField
                value={dateOfBirthValue}
                onChange={handleChangeDateOfBirth}
                error={clinicNameError}
                type="date"
              />
            </FormLayout>
            <FormLayout>
              <TextStyle variation="strong">Title</TextStyle>
              <Select
                options={titleOptions}
                onChange={handleSelectChangeTitle}
                value={selectedTitle}
              />
            </FormLayout>
          </FormLayout.Group>
          <TextStyle variation="strong">Trading Type</TextStyle>
          <Select
            options={[
              { label: "MetaTrader 4", value: "metatrader4" },
              { label: "MetaTrader 5", value: "metatrader5" },
            ]}
            placeholder="Please select"
            onChange={handleSelectChangeTradingType}
            value={tradingType}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
        }}
      />
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleChangeDateOfBirth(dateOfBirthValue) {
    setdateOfBirthValue(dateOfBirthValue);
    setClinicNameError("");
  }
  function handleChangeCreationDate(creationDate) {
    setcreationDate(creationDate);
    setCityError("");
  }
  function handleChangePhone(phoneValue) {
    setPhoneValue(phoneValue);
    setPhoneError("");
  }
  function handleChangeFirstName(firstNameValue) {
    setFirstNameValue(firstNameValue);
    setFirstNameError("");
  }
  function handleChangeLastName(lastNameValue) {
    setLastNameValue(lastNameValue);
    setLastNameError("");
  }
  function handleChangeEmail(emailValue) {
    setEmailValue(emailValue);
    setEmailError("");
  }

  function handleSave() {
    setPopupActive(true);
    const bodyObj = {
      first_name: firstNameValue,
      last_name: lastNameValue,
      email: emailValue,
      personal_mobile_number: phoneValue,
      date_of_birth: dateOfBirthValue,
      title_id: parseInt(selectedTitle),
      trading_type: tradingType,
    };
    !firstNameValue && delete bodyObj.first_name;
    !lastNameValue && delete bodyObj.last_name;
    !emailValue && delete bodyObj.email;
    !phoneValue && delete bodyObj.personal_mobile_number;
    !dateOfBirthValue && delete bodyObj.date_of_birth;
    axios
      .patch(`admin/v1/leads/${id}/update`, bodyObj)
      .then((res) => {
        setPopupActive(false);
        history.push(`/${switchParam(props.type)}/${id}`);
      })
      .catch((err) => setPopupActive(false));
  }
}
export default EditProfile;
