import React, { useCallback, useState, useEffect } from "react";
import {
  AppProvider,
  Card,
  FormLayout,
  Button,
  InlineError,
  DisplayText,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import axios from "../Assets/Libraries/axios.js";
import history from "../Assets/Libraries/history";
import Cookies from "js-cookie";

function ResetPassword() {
  var token = GetURLParameter("token");
  var email = GetURLParameter("email");
  const [passwordFieldValue, setPasswordFieldValue] = useState("");
  const [confirmPasswordFieldValue, setConfirmPasswordFieldValue] = useState(
    ""
  );
  const [isSuccess, setIsSuccess] = useState("");

  const [emailFieldValue, setEmailFieldValue] = useState("");
  const [tokenValue, setTokenValue] = useState(
    "kdiLK1BNYujbrg7NTg5bEUckWVWwG5fiCxXC21h41j2P6DlHUUts6xGiZIXJ"
  );

  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    //value && setIsDirty(true);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setPasswordFieldValue(value);
    //value && setIsDirty(true);
  }, []);
  const handleConfirmPasswordChange = useCallback((value) => {
    setConfirmPasswordFieldValue(value);
    //value && setIsDirty(true);
  }, []);
  const [errorPasswordTf, setErrorPasswordTf] = useState(<h1></h1>);

  useEffect(() => {
    token = GetURLParameter("token");
    var email = GetURLParameter("email");
    axios
      .get("admin/v1/password/find/" + token)
      .then((result) => {
        setIsSuccess(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const pageContent = isSuccess ? (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ minWidth: 400, marginTop: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <FormLayout>
            <DisplayText size="large">Reset Password</DisplayText>
            <TextStyle>Please enter a new password</TextStyle>
          </FormLayout>
        </div>

        <Card sectioned>
          <FormLayout>
            <TextField
              label="New Password"
              value={passwordFieldValue}
              onChange={handlePasswordChange}
              type="password"
            />
            <TextField
              label="Verify Password"
              value={confirmPasswordFieldValue}
              onChange={handleConfirmPasswordChange}
              type="password"
            />
            {errorPasswordTf}
            <Button fullWidth primary onClick={handleResetPasssword}>
              Reset Password
            </Button>
          </FormLayout>
        </Card>
      </div>
    </div>
  ) : (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ minWidth: 400, marginTop: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <FormLayout>
            <DisplayText size="large">
              Your password has been reset.
            </DisplayText>
            <TextStyle>
              Click Log In to sigmn in using your new password
            </TextStyle>
            <Button fullWidth primary onClick={handleSignIn}>
              Sign in
            </Button>
          </FormLayout>
        </div>
      </div>
    </div>
  );

  return <AppProvider>{pageContent}</AppProvider>;

  function handleResetPasssword(e) {
    const bodyObj = {
      email: email,
      token: token,
      password: passwordFieldValue,
      password_confirmation: confirmPasswordFieldValue,
    };
    if (passwordFieldValue != confirmPasswordFieldValue) {
      setErrorPasswordTf(
        <InlineError
          message="Please confirm your password"
          fieldID="myFieldID"
        />
      );
      // errorPasswordTf = (<InlineError message="Empty Password" fieldID="myFieldID" />);
    } else {
      axios
        .post("/admin/v1/password/reset", bodyObj)
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  }
  function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  }

  function handleSignIn(e) {
    history.push("/");
  }
}

export default ResetPassword;
