import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../Assets/Libraries/history";
import List from "./List";
import { AppProvider } from "@shopify/polaris";
import Add from "./Add";
import Edit from "./Edit";

function MainPages() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/pages" component={List} />
          <Route path="/pages/add" component={Add} />
          <Route path="/pages/:id" component={Edit} />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainPages;
