/* global gapi */

import React, { useCallback, useState, useEffect } from "react";
import {
  AppProvider,
  Card,
  FormLayout,
  Button,
  DisplayText,
  Image,
  TextField,
  Link,
  Toast,
  InlineError,
  Modal,
} from "@shopify/polaris";
import axios from "../Assets/Libraries/axios.js";
import history from "../Assets/Libraries/history";
import Cookies from "js-cookie";
import logo from "../Assets/Images/logo.svg";
import ReCAPTCHA from "react-google-recaptcha";

function Login() {
  const [emailFieldValue, setEmailFieldValue] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFieldValue, setPasswordFieldValue] = useState("");
  const [active, setActive] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  let errorPasswordTf;
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [valueErrorEmail, setValueErrorEmail] = useState("");
  let errorEmailTf;
  const [popupActive, setPopupActive] = useState(false);
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState("false");
  const [signInClicked, setSignInClicked] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  function handleError() {
    if (isEmailError) {
      errorEmailTf = (
        <InlineError message={valueErrorEmail} fieldID="myFieldID" />
      );
    } else {
      errorEmailTf = <h1></h1>;
    }
    if (isPasswordError) {
      errorPasswordTf = (
        <InlineError message={passwordErrorMessage} fieldID="myFieldID" />
      );
    } else {
      errorPasswordTf = <h1></h1>;
    }
  }
  handleError();

  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
  }, []);
  const handlePasswordFieldChange = useCallback((value) => {
    setPasswordFieldValue(value);
  }, []);

  useEffect(() => {
    Cookies.set("token", "null");
  }, []);

  const toastMarkup = active ? (
    <Toast
      content="These credentials do not match our records."
      onDismiss={toggleActive}
    />
  ) : null;
  var callback = function () {};

  // specifying verify callback function
  var verifyCallback = function (response) {};
  function onChange(value) {
    setCaptchaValue(value);
    setIsRecaptchaChecked("true");
  }

  return (
    <AppProvider>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "40%",
          transform: "translate(-50%, -50%)",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Image src={logo} alt="Logo" />

        <div style={{ minWidth: 400, marginTop: 20 }}>
          <div style={{ marginBottom: 30 }}>
            <DisplayText size="large">Login</DisplayText>
          </div>

          <FormLayout>
            <Card sectioned>
              <FormLayout>
                <TextField
                  type="email"
                  label="Email"
                  value={emailFieldValue}
                  onChange={handleEmailFieldChange}
                />
                {errorEmailTf}
                <div onKeyDown={handleKeyPress}>
                  <TextField
                    label="Password"
                    value={passwordFieldValue}
                    onChange={handlePasswordFieldChange}
                    type="password"
                  />
                </div>
                {/* <div></div> */}
                {/* <div></div> */}

                {errorPasswordTf}
                {/* <Recaptcha
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  render="explicit"
                  verifyCallback={verifyCallback}
                  onloadCallback={callback}
                /> */}

                {/* <div>ghiwaaaa</div> */}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ReCAPTCHA
                    sitekey="6LcaQboZAAAAAKod9wCA6iiKRaiyUMugZKaszkXw"
                    onChange={onChange}
                  />
                </div>
                {isRecaptchaChecked === "false" && signInClicked && (
                  <p style={{ color: "red" }}>
                    Please verify that you are not a robot.
                  </p>
                )}
                <Button fullWidth primary onClick={handleSignIn}>
                  Sign in
                </Button>
                <Link url="/forgotpassword">Forgot password?</Link>
              </FormLayout>
            </Card>
            <div>
              <div style={{ float: "left" }}>
                <p style={{ float: "left" }}>
                  {"© Copyright " +
                    new Date().getFullYear() +
                    " " +
                    process.env.REACT_APP_CLIENT_NAME}
                </p>
                <br />
                {/* <p style={{ float: "left" }}>
                  <Link url="https://creoshift.com/">A tool by creoshift</Link>
                </p> */}
              </div>
              <div style={{ float: "right" }}>
                {/* <a
                  href="mailto:support@creoshit.com"
                  style={{ textDecoration: "none" }}
                >
                  <Link>Contact Us</Link>
                </a> */}
              </div>
            </div>
          </FormLayout>
        </div>
        {toastMarkup}
        <Modal open={popupActive} loading={true}></Modal>
      </div>
    </AppProvider>
  );

  function handleSignIn(e) {
    if (emailFieldValue === "") {
      setValueErrorEmail("Please Enter your email");
      setIsEmailError(true);
    }
    if (passwordFieldValue === "") {
      setPasswordErrorMessage("Empty Password");
      setIsPasswordError(true);
    }
    if (isRecaptchaChecked === "true") {
      if (emailFieldValue === "") {
        setValueErrorEmail("Please Enter your email");
        setIsEmailError(true);
      } else if (
        !emailFieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        setValueErrorEmail("Please enter a valid email");
        setIsEmailError(true);
      }

      if (passwordFieldValue === "") {
        setPasswordErrorMessage("Empty Password");
        setIsPasswordError(true);
      }
      if (
        passwordFieldValue != "" &&
        emailFieldValue != "" &&
        emailFieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        setPopupActive(true);
        const form_data = new FormData();
        form_data.append("email", emailFieldValue);
        form_data.append("password", passwordFieldValue);
        form_data.append("device_name", "android");
        form_data.append("g-recaptcha-response", captchaValue);

        axios
          .post("/admin/v1/auth/login", form_data)
          .then((res) => {
            setPopupActive(false);
            Cookies.set("4t_access_token", res.data["token"], { expires: 7 });
            Cookies.set("4t-password", passwordFieldValue);
            Cookies.set("4t-firstname", res.data.user.first_name);
            Cookies.set("4t-lastname", res.data.user.last_name);
            Cookies.set("4t-adminrole", res.data.user.roles[0].name);
            // Cookies.set("roleId", res.data.roles[0].id);
            // Cookies.set("imageUrl", res.data.user.profile_image_url);
            res.data.user.roles[0].name === "lead-admin" &&
              history.push("/leads");
            res.data.user.roles[0].name === "promofix-admin" &&
              history.push("/promofix-leads");
            res.data.user.roles[0].name !== "promofix-admin" &&
              res.data.user.roles[0].name !== "lead-admin" &&
              history.push("/users");
          })
          .catch((error) => {
            window.grecaptcha.reset();
            setIsRecaptchaChecked("false");
            showErrorMsg();
            setPopupActive(false);
          });
      }
    } else {
      setSignInClicked(true);
    }
  }
  function showErrorMsg() {
    setPasswordErrorMessage("These credentials do not match our records.");
    setIsPasswordError(true);
  }

  function toggleActive() {
    setActive((active) => !active);
  }
  function handleKeyPress(event) {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      handleSignIn();
    }
  }
}
export default Login;
