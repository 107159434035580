import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  Loading,
  Icon,
  ChoiceList,
  Filters,
  Card,
  TextStyle,
  ButtonGroup,
  Modal,
  TextContainer,
  Toast,
  Tooltip,
  Spinner,
  Link,
  Badge,
  RadioButton,
  Stack,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import axios from "../Assets/Libraries/axios";
import history from "../Assets/Libraries/history";
import {
  CircleTickOutlineMinor,
  RiskMajor,
  LinkMinor,
  CircleTickMajor,
  CircleAlertMajor,
} from "@shopify/polaris-icons";
import emptyIcon from "../Assets/Images/emptyList.svg";
import Pagination2 from "@material-ui/lab/Pagination";
import moment from "moment";
import { ExportMinor } from "@shopify/polaris-icons";

export default function ListOfLeads() {
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [perPage, setPerPage] = useState(100);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [countries, setCountries] = useState([]);
  const [currentID, setCurrentID] = useState(0);
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState("Account Disactivated");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} />
  ) : null;
  const [popupActive, setPopupActive] = useState(false);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    console.log(value);
    setPage(value);
  };
  const [evFilterValue, setEvFilterValue] = useState("ev_true");
  const handleChangeEvFilterValue = useCallback(
    (_checked, newValue) => setEvFilterValue(newValue),
    []
  );
  const [accountTypeFilterValue, setAccountTypeFilterValue] =
    useState("rb_live");
  const handleChangeAccountType = useCallback(
    (_checked, newValue) => setAccountTypeFilterValue(newValue),
    []
  );
  useEffect(() => {
    Cookies.get("4t_access_token") === "null" ||
    Cookies.get("4t_access_token") === undefined ||
    Cookies.get("4t_access_token") === null ||
    Cookies.get("4t_access_token") === "undefined"
      ? history.push("/")
      : getData();
  }, [page, queryValue, availability, evFilterValue, accountTypeFilterValue]);
  function getData() {
    axios
      .get(
        `admin/v1/leads?filter[${
          availability === "" ? "first_name" : availability
        }]=${
          availability == "ev_status"
            ? evFilterValue == "ev_true"
              ? "true"
              : "false"
            : availability == "access_type"
            ? accountTypeFilterValue == "rb_live"
              ? "0"
              : accountTypeFilterValue == "rb_demo"
              ? "1"
              : "2"
            : queryValue
        }&page=${page}&per_page=${perPage}`
      )
      .then((result) => {
        if (result.data.data.data.length === 0) {
          setIsListEmpty(true);
          //  toggleIsEmpty()
        } else {
          setIsListEmpty(false);
        }
        setIsLoading(false);
        setItems(
          result.data.data.data.map((item, index) => [
            item.created_at &&
              moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
            String(item.access_type) === "0" ? (
              <Badge status="new">Live</Badge>
            ) : String(item.access_type) === "1" ? (
              <Badge status="info">Demo</Badge>
            ) : (
              <Badge status="success">Lead</Badge>
            ),
            <Link
              monochrome
              onClick={() => window.open("/leads/" + item.id, "_blank")}
            >
              <b>{item.first_name + " " + item.last_name}</b>
            </Link>,
            item?.email_verified_at ? (
              <Icon source={CircleTickMajor} color="primary"></Icon>
            ) : (
              <Icon source={CircleAlertMajor} color="critical"></Icon>
            ),
            item?.email && item.email,
            item?.personal_mobile_number && item.personal_mobile_number,
            item.other &&
              (item.other["CD_status"] === 200 ? (
                <Icon source={CircleTickMajor} color="primary"></Icon>
              ) : (
                <Tooltip
                  content={
                    item.other["CD_error"].ModelState
                      ? String(
                          Object.keys(item.other["CD_error"].ModelState)[0]
                        ).replace(/\.|-|_/gi, " ")
                      : item.other["CD_error"].Message
                  }
                >
                  <Icon source={CircleAlertMajor} color="critical"></Icon>
                </Tooltip>
              )),
            item.more_info ? (
              String(item.token) !== "0" ? (
                <div
                  id={String(
                    item.more_info && item.more_info.verification_link
                  )}
                  onClick={(e) =>
                    handleOpenLink(
                      String(item.more_info && item.more_info.verification_link)
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={LinkMinor}></Icon>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            ),
            item?.utm && (
              <ul>
                {Object.keys(item.utm).map(function (keyName, keyIndex) {
                  return (
                    <li key={keyName}>{`${keyName}: ${item.utm[keyName]}`}</li>
                  );
                })}
              </ul>
            ),
          ])
        );
        setTotalPages(Math.ceil(result.data.data.total / perPage));
      })
      .catch((err) => console.log(err));
  }
  const switchFilterValue = () => {
    switch (availability) {
      case "ev_status":
        return "";
        break;

      default:
        return "";
    }
  };

  function recreateAccountError() {
    setPopupActive(false);
    setToastMessage("Internal server error");
    toggleActive();
  }
  function handleOpenLink(url) {
    console.log(url);
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handleViewDetails(e) {
    window.open("/leads/" + e.currentTarget.id, "_blank");
  }

  function handleDelete(e) {
    setCurrentID(e.currentTarget.id);
    setActiveDeleteMessage(true);
  }

  function handleFiltersQueryChange(queryValue) {
    console.log(queryValue);
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "First Name", value: "first_name" },
            { label: "Last Name", value: "last_name" },
            { label: "Email", value: "email" },
            { label: "Phone Number", value: "personal_mobile_number" },
            { label: "Regulator", value: "regulator" },
            { label: "EV Status", value: "ev_status" },
            { label: "Account Type", value: "access_type" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      </div>
    )
  );
  const [activeDeleteMessage, setActiveDeleteMessage] = useState(false);
  const handleExport = () => {
    // setPopupActive(true);
    // axios
    //   .get("/admin/v1/leads/export")
    //   .then((res) => {
    //     setPopupActive(false);
    //     setToastMessage("Users list exported successfully");
    //     toggleActive();
    //   })
    //   .catch((error) => {});

    window.open(
      `${
        process.env.REACT_APP_BASE_URL
      }admin/v1/leads-with-utm/export?token=LpodkkjhyyT54@l_w&filter[${
        availability === "" ? "first_name" : availability
      }]=${queryValue}&page=${page}&per_page=${perPage}`
    );
  };

  return (
    <Page
      fullWidth
      title="Campaign"
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: handleExport,
        },
      ]}
    >
      {loadingMarkup}
      <Card>
        <div className="stickyHeader">
          {availability == "ev_status" && (
            <Card.Section>
              <Stack horizontal>
                <RadioButton
                  label="Checked"
                  checked={evFilterValue === "ev_true"}
                  id="ev_true"
                  onChange={handleChangeEvFilterValue}
                />
                <RadioButton
                  label="Error"
                  id="ev_false"
                  checked={evFilterValue === "ev_false"}
                  onChange={handleChangeEvFilterValue}
                />
              </Stack>
            </Card.Section>
          )}
          {availability == "access_type" && (
            <Card.Section>
              <Stack horizontal>
                <RadioButton
                  label="Live"
                  checked={accountTypeFilterValue === "rb_live"}
                  id="rb_live"
                  onChange={handleChangeAccountType}
                />
                <RadioButton
                  label="Demo"
                  id="rb_demo"
                  checked={accountTypeFilterValue === "rb_demo"}
                  onChange={handleChangeAccountType}
                />
                <RadioButton
                  label="Lead"
                  id="rb_lead"
                  checked={accountTypeFilterValue === "rb_lead"}
                  onChange={handleChangeAccountType}
                />
              </Stack>
            </Card.Section>
          )}
          <Card.Section>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
            />
          </Card.Section>{" "}
        </div>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Date</TextStyle>,
            <TextStyle variation="strong">Account Type</TextStyle>,
            <TextStyle variation="strong">Name</TextStyle>,
            <TextStyle variation="strong">EV status</TextStyle>,
            <TextStyle variation="strong">Email</TextStyle>,
            <TextStyle variation="strong">Phone</TextStyle>,
            <TextStyle variation="strong">CD Status</TextStyle>,
            <TextStyle variation="strong">Verification Link</TextStyle>,
            <TextStyle variation="strong">UTM</TextStyle>,
          ]}
          rows={items}
          verticalAlign="middle"
        />
        {totalPages > 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            {/* <Pagination
              hasPrevious={currentPage > 1 && true}
              onPrevious={() => {
                setCurrentPage(currentPage - 1);
              }}
              hasNext={currentPage < totalPages && true}
              onNext={() => {
                setCurrentPage(currentPage + 1);
              }}
            /> */}
            <Pagination2
              count={totalPages}
              page={page}
              onChange={handleChange}
              size="large"
            />
          </div>
        )}
        {emtyState}
      </Card>
      {toastMarkup}
      <Modal
        open={activeDeleteMessage}
        onClose={handleChangeDeleteMessage}
        title="Delete User"
        primaryAction={{
          content: "No",
          onAction: handleNoDelete,
        }}
        secondaryActions={[
          {
            content: "Yes",
            onAction: () => handleYesDelete(currentID),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to delete this lead ?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
  function handleSort() {
    getData(currentPage, queryValue, availability);
  }

  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookies.set("clickedItem", json_str);
    history.push("/admin/products/" + currentID);
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }

  function handleChangeDeleteMessage() {
    setActiveDeleteMessage(false);
  }

  function handleNoDelete() {
    setActiveDeleteMessage(!activeDeleteMessage);
  }
  function handleYesDelete(id) {
    setActiveDeleteMessage(!activeDeleteMessage);
    axios
      .delete(`admin/v1/leads/${id}/delete`)
      .then((result) => {
        setToastMessage("This lead is deleted successfully");
        toggleActive();
        getData();
      })
      .catch((err) => console.log(err));
  }
}
