import React, { useEffect } from "react";
import axios from "axios";
import history from "./history";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.set("4t_access_token", null);
      history.push("/");

      // Cookies.set("4t-firstname", null);
      // Cookies.set("4t-lastname", null);
      // Cookies.set("4t-adminrole", null);
    } else {
      return Promise.reject(error);
    }
  }
);

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${Cookies.get("4t_access_token")}`;
  return config;
});

export default instance;
