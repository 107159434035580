import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../Assets/Libraries/history";
import List from "./List";
import NavigationLayout from "../Components/NavigationLayout";
import MainEditPromofixLeads from "./MainEditPromofixLeads";

function MainPromofixLeads() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/promofix-leads">
            <List type={"promofix"} />
          </Route>
          <Route path="/promofix-leads/:id">
            <MainEditPromofixLeads type={"promofix"} />
          </Route>
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default MainPromofixLeads;
