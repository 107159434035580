import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  AppProvider,
  ActionList,
  Card,
  TextField,
  TextContainer,
  ContextualSaveBar,
  FormLayout,
  Modal,
  Frame,
  Layout,
  Loading,
  Navigation,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Toast,
  TopBar,
} from "@shopify/polaris";
import {
  ProfileMinor,
  LogOutMinor,
  CustomersMinor,
  PageMajor,
  SettingsMajor,
  EmailNewsletterMajor,
  MarketingMajor,
  CategoriesMajor,
} from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import history from "../Assets/Libraries/history";
import whiteLogo from "../Assets/Images/whiteLogo.png";

function NavigationLayout(props) {
  const defaultState = useRef({
    emailFieldValue: "dharma@jadedpixel.com",
    nameFieldValue: "Jaded Pixel",
  });
  const skipToContentRef = useRef(null);

  const [toastActive, setToastActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue
  );
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");

  const handleSubjectChange = useCallback(
    (value) => setSupportSubject(value),
    []
  );
  const handleMessageChange = useCallback(
    (value) => setSupportMessage(value),
    []
  );
  const handleDiscard = useCallback(() => {
    setEmailFieldValue(defaultState.current.emailFieldValue);
    setNameFieldValue(defaultState.current.nameFieldValue);
    setIsDirty(false);
  }, []);
  const handleSave = useCallback(() => {
    defaultState.current.nameFieldValue = nameFieldValue;
    defaultState.current.emailFieldValue = emailFieldValue;

    setIsDirty(false);
    setToastActive(true);
    setStoreName(defaultState.current.nameFieldValue);
  }, [emailFieldValue, nameFieldValue]);
  const handleNameFieldChange = useCallback((value) => {
    setNameFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);
  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;

  const userMenuActions = [
    {
      items: [
        {
          content: "Your profile",
          icon: ProfileMinor,
          url: "/profile",
        },
      ],
    },
    {
      items: [
        {
          content: "Log out",
          icon: LogOutMinor,
          url: "/",
          onAction: () => Cookies.set("4t_access_token", null),
        },
      ],
    },
  ];

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={
        Cookies.get("4t-firstname") &&
        Cookies.get("4t-firstname") + " " + Cookies.get("4t-lastname")
      }
      initials={
        Cookies.get("4t-firstname") &&
        Cookies.get("4t-firstname").charAt(0) +
          " " +
          Cookies.get("4t-lastname").charAt(0)
      }
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
      avatar={Cookies.get("imageUrl")}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      // onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={
          Cookies.get("4t-adminrole") === "lead-admin"
            ? [
                {
                  url: "/leads",
                  label: "Leads",
                  icon: CustomersMinor,
                  selected: window.location.href.indexOf("/leads") > -1 && true,
                },
              ]
            : Cookies.get("4t-adminrole") === "promofix-admin"
            ? [
                {
                  url: "/promofix-leads",
                  label: "Promofix Leads",
                  icon: CustomersMinor,
                  selected:
                    window.location.href.indexOf("/promofix-leads") > -1 &&
                    true,
                },
              ]
            : [
                {
                  url: "/users",
                  label: "Users",
                  icon: CustomersMinor,
                  selected: window.location.href.indexOf("/users") > -1 && true,
                },
                {
                  url: "/leads",
                  label: "Leads",
                  icon: CustomersMinor,
                  selected: window.location.href.indexOf("/leads") > -1 && true,
                },
                {
                  url: "/africa-leads",
                  label: "Africa Leads",
                  icon: CustomersMinor,
                  selected:
                    window.location.href.indexOf("/africa-leads") > -1 && true,
                },
                {
                  url: "/promofix-leads",
                  label: "Promofix Leads",
                  icon: CustomersMinor,
                  selected:
                    window.location.href.indexOf("/promofix-leads") > -1 &&
                    true,
                },
                {
                  url: "/newsletter-subscribers",
                  label: "Newsletter Subscribers",
                  icon: EmailNewsletterMajor,
                  selected:
                    window.location.href.indexOf("/newsletter-subscribers") >
                      -1 && true,
                },
                {
                  url: "/campaign",
                  label: "Campaign",
                  icon: MarketingMajor,
                  selected:
                    window.location.href.indexOf("/campaign") > -1 && true,
                },
                {
                  url: "/pages",
                  label: "Pages",
                  icon: PageMajor,
                  selected: window.location.href.indexOf("/pages") > -1 && true,
                },
                {
                  url: "/live-market-prices",
                  label: "Live market prices",
                  icon: CategoriesMajor,
                  selected:
                    window.location.href.indexOf("/live-market-prices") > -1 &&
                    true,
                },
              ]
        }
      ></Navigation.Section>

      {Cookies.get("4t-adminrole") !== "lead-admin" &&
      Cookies.get("4t-adminrole") !== "promofix-admin" ? (
        <Navigation.Section
          separator
          items={[
            {
              icon: SettingsMajor,
              label: "Settings",
              url: "/settings",
              // disabled: isDisabled,
              selected: window.location.href.indexOf("/settings") > -1 && true,
            },
          ]}
        />
      ) : null}
    </Navigation>
  );
  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const actualPageMarkup = (
    <Page title="Account">
      <Layout>
        {skipToContentTarget}
        <Layout.AnnotatedSection
          title="Account details"
          description="Jaded Pixel will use this as your account information."
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Full name"
                value={nameFieldValue}
                onChange={handleNameFieldChange}
              />
              <TextField
                type="email"
                label="Email"
                value={emailFieldValue}
                onChange={handleEmailFieldChange}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={toggleModalActive}
      title="Contact support"
      primaryAction={{
        content: "Send",
        onAction: toggleModalActive,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Subject"
            value={supportSubject}
            onChange={handleSubjectChange}
          />
          <TextField
            label="Message"
            value={supportMessage}
            onChange={handleMessageChange}
            multiline
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const theme = {
    colors: {
      topBar: {
        background: "#646464",
      },
    },
    logo: {
      width: 124,
      topBarSource: whiteLogo,
      url: "/users",
    },
  };

  useEffect(() => {
    //  console.log("nav");
    // console.log("4t_access_token=", Cookies.get("4t_access_token"));
    // if (
    //   Cookies.get("4t_access_token") === "null" ||
    //   Cookies.get("4t_access_token") === undefined ||
    //   Cookies.get("4t_access_token") === null ||
    //   Cookies.get("4t_access_token") === "undefined"
    // ) {
    //   history.push("/");
    // } else {
    // }
  }, []);
  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        theme={theme}
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            ContextualSaveBar: {
              save: "Save",
              discard: "Discard",
            },
            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          {props.children}
        </Frame>
      </AppProvider>
    </div>
  );
}
export default React.memo(NavigationLayout);
