import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Modal,
  Thumbnail,
  Caption,
  DropZone,
  Checkbox,
  Stack,
  TextStyle,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
} from "@shopify/polaris";
import axios from "../Assets/Libraries/axios";
import Cookies from "js-cookie";
import history from "../Assets/Libraries/history";
import { Editor } from "@tinymce/tinymce-react";
import ReactTagInput from "@pathofdev/react-tag-input";
import { useParams } from "react-router-dom";
import NavigationLayout from "../Components/NavigationLayout";

function Edit() {
  let { id } = useParams();
  const [valueTitleEn, setValueTitleEn] = useState("");
  const [valueTitleAr, setValueTitleAr] = useState("");
  const [valuePageTitleEn, setValuePageTitleEn] = useState("");
  const [valuePageTitleAr, setValuePageTitleAr] = useState("");
  const [valueDescriptionEn, setValueDescriptionEn] = useState("");
  const [valueDescriptionAr, setValueDescriptionAr] = useState("");
  const [valueContentEn, setValueContentEn] = useState("");
  const [valueContentAr, setValueContentAr] = useState("");
  const [valueAlias, setValueAlias] = useState("");
  const [valueMetaName, setValueMetaName] = useState("");
  const [valueMetaContent, setValueMetaContent] = useState("");
  const [valueKeywords, setValueKeywords] = useState("");

  const [checked, setChecked] = useState(false);
  // const handleChangeTitleEn = (e) => {
  //     setValueTitleEn(e.target.getContent())
  // }
  // const handleChangeTitleAr = (e) => {
  //     setValueTitleAr(e.target.getContent())
  // }
  // const handleChangePageTitleEn = (e) => {
  //     setValuePageTitleEn(e.target.getContent())
  // }
  // const handleChangePageTitleAr = (e) => {
  //     setValuePageTitleAr(e.target.getContent())
  // }
  const handleChangeDescriptionEn = (e) => {
    setValueDescriptionEn(e.target.getContent());
  };
  const handleChangeDescriptionAr = (e) => {
    setValueDescriptionAr(e.target.getContent());
  };
  const handleChangeContentEn = (e) => {
    setValueContentEn(e.target.getContent());
  };
  const handleChangeContentAr = (e) => {
    setValueContentAr(e.target.getContent());
  };

  let imageType = "";

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState("");
  const [imageKey, setImageKey] = useState("");

  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = useCallback(
    (files) => {
      setPopupActive(true);
      setImagePreview(
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = files[files.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/Images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/Images/s3", form_data)
        .then((res) => {
          setImageKey(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, files[files.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [files]
  );

  const [imagePreviewThumbnail, setImagePreviewThumbnail] = useState("");
  const [imageKeyThumbnail, setImageKeyThumbnail] = useState("");
  const [filesThumbnail, setFilesThumbnail] = useState([]);
  const handleDropZoneDropThumbnail = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesThumbnail((filesThumbnail) => [
        ...filesThumbnail,
        ...acceptedFiles,
      ]),
    []
  );
  const fileUploadThumbnail = !filesThumbnail.length && <DropZone.FileUpload />;
  let uploadedFilesThumbnail = filesThumbnail.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesThumbnail[filesThumbnail.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesThumbnail[filesThumbnail.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesThumbnail[filesThumbnail.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesThumbnail[filesThumbnail.length - 1].name}{" "}
        <Caption>
          {filesThumbnail[filesThumbnail.length - 1].type} bytes
        </Caption>
      </div>
    </Stack>
  );
  const sendImageThumbnail = useCallback(
    (filesThumbnail) => {
      setPopupActive(true);
      setImagePreviewThumbnail(
        validImageTypes.indexOf(
          filesThumbnail[filesThumbnail.length - 1].type
        ) > 0
          ? window.URL.createObjectURL(
              filesThumbnail[filesThumbnail.length - 1]
            )
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
      );
      imageType = filesThumbnail[filesThumbnail.length - 1].type;
      let headersVar = null;
      let url2 = "";
      let urlAmazon = "https://basma.mintlearn.a2hosted.com/admin/v1/Images/s3";
      const form_data = new FormData();
      form_data.append("content_type", imageType);
      axios
        .post("/admin/v1/Images/s3", form_data)
        .then((res) => {
          setImageKeyThumbnail(res.data.key);
          url2 = res.data.url;
          headersVar = res.data.headers;
          axios
            .put(url2, filesThumbnail[filesThumbnail.length - 1], {
              headers: {
                "x-amz-acl": ["public-read-write"],
                "Content-Type": imageType,
              },
            })
            .then((res) => {
              setPopupActive(false);
            })
            .catch((err) => setPopupActive(false));
        })
        .catch((err) => setPopupActive(false));
    },

    [filesThumbnail]
  );

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    axios
      .get("admin/v1/pages/" + id)
      .then((result) => {
        setChecked(result.data.data.active);
        setValueAlias(result.data.data.alias);
        setValueContentEn(result.data.data.content.en);
        setValueContentAr(result.data.data.content.ar);
        setValueDescriptionEn(result.data.data.description.en);
        setValueDescriptionAr(result.data.data.description.ar);
        setImagePreview(result.data.data.image);
        setImagePreviewThumbnail(result.data.data.image_thumb);
        setValueMetaContent(result.data.data.meta_content);
        setValueMetaName(result.data.data.meta_name);
        setValueTitleEn(result.data.data.title.en);
        setValueTitleAr(result.data.data.title.ar);
        setValuePageTitleEn(result.data.data.page_title.en);
        setValuePageTitleAr(result.data.data.page_title.ar);
        setTags(result.data.data.keywords.split(","));
      })
      .catch((err) => console.log(err));
  }

  const [tags, setTags] = useState([]);

  const [isUploadingAtive, setIsUploadingAtive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);

  return (
    <NavigationLayout>
      <Page
        title="Edit Page"
        breadcrumbs={[{ content: "List Of pages", url: "/pages" }]}
      >
        <Card sectioned>
          <FormLayout>
            <TextStyle variation="strong">Title En</TextStyle>
            <TextField value={valueTitleEn} onChange={handleChangeTitleEn} />

            {/* <Editor apiKey='z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve'
                            value={valueTitleEn}
                            init={{
                                branding: false,
                                height: 200,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                            }}
                            onChange={handleChangeTitleEn}
                        /> */}
            <TextStyle variation="strong">Title Ar</TextStyle>
            <TextField value={valueTitleAr} onChange={handleChangeTitleAr} />

            {/* <Editor apiKey='z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve'
                            value={valueTitleAr}
                            init={{
                                branding: false,
                                height: 200,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                            }}
                            onChange={handleChangeTitleAr}
                        /> */}

            <TextStyle variation="strong">Page Title En</TextStyle>
            <TextField
              value={valuePageTitleEn}
              onChange={handleChangePageTitleEn}
            />
            {/* <Editor apiKey='z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve'
                            value={valuePageTitleEn}
                            init={{
                                branding: false,
                                height: 200,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                            }}
                            onChange={handleChangePageTitleEn}
                        /> */}
            <TextStyle variation="strong">Page Title Ar</TextStyle>
            <TextField
              value={valuePageTitleAr}
              onChange={handleChangePageTitleAr}
            />
            {/* <Editor apiKey='z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve'
                            value={valuePageTitleAr}
                            init={{
                                branding: false,
                                height: 200,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                            }}
                            onChange={handleChangePageTitleAr}
                        /> */}

            <TextStyle variation="strong">Description En</TextStyle>
            {/* <TextField
                            value={valueQuestionEn}
                            onChange={handleChangeQuestionEn}
                        /> */}

            <Editor
              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
              value={valueDescriptionEn}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
              }}
              onChange={handleChangeDescriptionEn}
            />
            <TextStyle variation="strong">Description Ar</TextStyle>
            {/* <TextField
                            value={valueQuestionEn}
                            onChange={handleChangeQuestionEn}
                        /> */}

            <Editor
              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
              value={valueDescriptionAr}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
              }}
              onChange={handleChangeDescriptionAr}
            />
            <TextStyle variation="strong">Content En</TextStyle>
            {/* <TextField
                            value={valueQuestionEn}
                            onChange={handleChangeQuestionEn}
                        /> */}

            <Editor
              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
              value={valueContentEn}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
              }}
              onChange={handleChangeContentEn}
            />
            <TextStyle variation="strong">Content Ar</TextStyle>
            {/* <TextField
                            value={valueQuestionEn}
                            onChange={handleChangeQuestionEn}
                        /> */}

            <Editor
              apiKey="z7j0xye1gwr8ca7fdbmv4hvz5t1wimm6gxjsm4g8lzgxygve"
              value={valueContentAr}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
              }}
              onChange={handleChangeContentAr}
            />
            <TextStyle variation="strong">Alias</TextStyle>

            <TextField value={valueAlias} onChange={handleChangeAlias} />
            <TextStyle variation="strong">Meta Name</TextStyle>

            <TextField value={valueMetaName} onChange={handleChangeMetaName} />
            <TextStyle variation="strong">Meta Content</TextStyle>

            <TextField
              value={valueMetaContent}
              onChange={handleChangeMetaContent}
            />
            <TextStyle variation="strong">Keywords</TextStyle>
            {/* <TagsInput value={tags} onChange={handleChangeTags} /> */}
            {/* <TextField
                            value={valueKeywords}
                            onChange={handleChangeKeywords}
                        /> */}
            <ReactTagInput tags={tags} onChange={handleChangeTags} />

            <TextStyle variation="strong">Image</TextStyle>
            <Thumbnail size="large" source={imagePreview} />
            <DropZone onDrop={handleDropZoneDrop} onDropAccepted={sendImage}>
              {uploadedFiles}
              {fileUpload}
            </DropZone>
            <TextStyle variation="strong">Thumbnail</TextStyle>
            <Thumbnail size="large" source={imagePreviewThumbnail} />
            <DropZone
              onDrop={handleDropZoneDropThumbnail}
              onDropAccepted={sendImageThumbnail}
            >
              {uploadedFilesThumbnail}
              {fileUploadThumbnail}
            </DropZone>
            <Checkbox
              label="Is Active"
              checked={checked}
              onChange={handleChangeCb}
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: "Save",
            onClick: handleSave,
          }}
          secondaryActions={[
            {
              content: "Delete",
              destructive: true,
              onClick: () =>
                axios
                  .delete("/admin/v1/pages/" + id + "/delete")
                  .then((result) => {
                    history.push("/pages");
                  })
                  .catch((err) => console.log(err)),
            },
          ]}
        />
        <Modal open={popupActive} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
  function handleChangeTags(tags) {
    setTags(tags);
  }
  function handleChangeTitleEn(valueTitleEn) {
    setValueTitleEn(valueTitleEn);
  }
  function handleChangeTitleAr(valueTitleAr) {
    setValueTitleAr(valueTitleAr);
  }
  function handleChangePageTitleEn(valuePageTitleEn) {
    setValuePageTitleEn(valuePageTitleEn);
  }
  function handleChangePageTitleAr(valuePageTitleAr) {
    setValuePageTitleAr(valuePageTitleAr);
  }

  function handleChangeCb(checked) {
    setChecked(checked);
  }
  function handleChangeAlias(valueAlias) {
    setValueAlias(valueAlias);
  }
  function handleChangeMetaName(valueMetaName) {
    setValueMetaName(valueMetaName);
  }
  function handleChangeMetaContent(valueMetaContent) {
    setValueMetaContent(valueMetaContent);
  }
  function handleChangeKeywords(valueKeywords) {
    setValueKeywords(valueKeywords);
  }

  function handleSave() {
    setPopupActive(true);

    const bodyObj = {
      title: {
        en: valueTitleEn,
        ar: valueTitleAr,
      },

      page_title: {
        en: valuePageTitleEn,
        ar: valuePageTitleAr,
      },
      description: {
        en: valueDescriptionEn,
        ar: valueDescriptionAr,
      },
      content: {
        en: valueContentEn,
        ar: valueContentAr,
      },
      alias: valueAlias,
      meta_name: valueMetaName,
      meta_content: valueMetaContent,
      keywords: valueKeywords,
      image: imageKey,
      image_thumb: imageKeyThumbnail,
      active: checked,
    };
    if (imageKey === "") {
      delete bodyObj.image;
    }
    if (imageKeyThumbnail === "") {
      delete bodyObj.image_thumb;
    }
    axios
      .post("/admin/v1/pages", bodyObj)
      .then((res) => {
        history.push("/pages");
        setPopupActive(false);

        //  history.push('/admin/faqsupport/viewCategory')
      })
      .catch((err) => setPopupActive(false));
  }
}
export default Edit;
