import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../Assets/Libraries/history";
import List from "./List";
import MainEdit from "./MainEdit";
import NavigationLayout from "../Components/NavigationLayout";
function MainUsers() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/users">
            <List type={"users"} />
          </Route>
          <Route path="/users/:id">
            <MainEdit type={"users"} />
          </Route>
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default MainUsers;
