import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Avatar,
  Card,
  TextStyle,
  ButtonGroup,
  Badge,
} from "@shopify/polaris";

import Cookies from "js-cookie";
import axios from "../Assets/Libraries/axios";
import history from "../Assets/Libraries/history";
import { Pagination } from "@zendeskgarden/react-pagination";
import emptyIcon from "../Assets/Images/emptyList.svg";

export default function AdminUsers() {
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [perPage, setPerPage] = useState(13);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [isButtonVisible, setButtonVisible] = useState("none");
  const [isButtonCreateVisible, setButtonCreateVisible] = useState("block");

  let valueFilter = "";
  let statusColors = ["default", "success", "info", "attention", "warning"];
  useEffect(() => {
    // document.getElementById("buttonDiv").style.display = "block";
    getData();
  }, []);
  function getData() {
    valueFilter = availability;
    if (sortState === "-id") {
      setSortState("id");
    } else {
      setSortState("-id");
    }
    if (valueFilter === "") {
      valueFilter = "first_name";
    }

    axios
      .get("/admin/v1/management/users")
      .then((result) => {
        setIsLoading(false);
        let isVis = true;

        if (result.data.data.data.length === 0) {
          setIsListEmpty(true);
        }

        if (String(Cookies.get("roleId")) === "5") {
          isVis = "block";
          setButtonCreateVisible("block");
        } else {
          // isVis = "none";
          // setButtonCreateVisible("none");
        }

        arrayOfObjects = result.data.data.data.map((item) => ({
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          email: item.email,
          password: item.password,
          roleId: item.roles[0] && item.roles[0].id,
          active: item.active,
          imageUrl: item.profile_image_url,
        }));

        setItems(
          result.data.data.data.map((item) => [
            item.id,
            <Avatar source={item.profile_image_url}></Avatar>,
            item.first_name,
            item.last_name,
            item.email,
            item.roles[0] && (
              <Badge status={statusColors[item.roles[0].id - 1]}>
                {item.roles[0].name}
              </Badge>
            ),

            <ButtonGroup>
              <div id="buttonDiv" style={{ display: isVis }}>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </div>
            </ButtonGroup>,
          ])
        );

        setTotalPages(Math.ceil(result.data.data.total / perPage));
        var pagination = document.getElementById("pagination");
        if (totalPages > 2) {
          pagination.style.display = "block";
        }
      })
      .catch((err) => console.log(err));
  }

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
    getData();
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
    getData();
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "Title", value: "title" },
            { label: "Description", value: "description" },
            { label: "Dimension", value: "dimension" },
            { label: "Weight", value: "weight" },
            { label: "Price", value: "price" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
          // allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <Page
      fullWidth
      //title="List Of Users"
      // primaryAction={{
      //   content: "Add User",
      //   url: "/users/add"
      // }}
    >
      {" "}
      {loadingMarkup}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "100%" }}>
          <div style={{ float: "left" }}>
            <DisplayText size="large">List Of Users</DisplayText>
          </div>
          <div style={{ float: "right", display: isButtonCreateVisible }}>
            <Button primary onClick={(e) => handleAddUser(e)}>
              Add User
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Card>
            {/* <Card.Subsection>
                <Filters
                  queryValue={queryValue}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={handleFiltersQueryChange}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleFiltersClearAll}
                />
              </Card.Subsection> */}
            <Card.Subsection>
              <DataTable
                columnContentTypes={[
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                ]}
                headings={[
                  <TextStyle variation="strong">ID</TextStyle>,
                  <TextStyle variation="strong">Thumbnail</TextStyle>,
                  <TextStyle variation="strong">First Name</TextStyle>,
                  <TextStyle variation="strong">Last Name</TextStyle>,
                  <TextStyle variation="strong">Email</TextStyle>,
                  <TextStyle variation="strong">Role</TextStyle>,
                  <TextStyle variation="strong"></TextStyle>,
                ]}
                rows={items}
                sortable={[false, false, false, false, false, false, false]}
                defaultSortDirection="descending"
                initialSortColumnIndex={0}
                onSort={handleSort}
              />
              <div id="pagination" style={{ marginTop: 20, display: "none" }}>
                <Pagination
                  totalPages={totalPages}
                  pagePadding={3}
                  currentPage={currentPage}
                  // onChange={currentPage => setCurrentPage({ currentPage }),console.log(currentPage)}
                  onChange={handlePageChange}
                />
              </div>
              {emtyState}
            </Card.Subsection>
          </Card>
        </div>
      </div>
    </Page>
  );
  function handleAddUser(e) {
    history.push("/adminusers/add");
  }

  function handleSort() {
    getData();
  }

  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    var json_str = JSON.stringify(
      findArrayElementById(arrayOfObjects, currentID)
    );
    Cookies.set("clickedItem", json_str);
    history.push("/adminusers/" + currentID);
  }

  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  function handlePageChange(currentPage) {
    setCurrentPage(currentPage);
    getData();
  }
}
