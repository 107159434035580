import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Libraries/axios";
import history from "../../Assets/Libraries/history";
import axios2 from "axios";
// import SelectSearch from "react-select-search";
import { useParams } from "react-router-dom";
import Select from "react-select";

const Edit = () => {
  const { priceId, symbolId } = useParams();
  const [selectedSymbol, setSelectedSymbol] = useState("");

  const handleSelectChangeSymbol = (value) => {
    setSelectedSymbol(value);
  };
  const [optionsSymbols, setOptionsSymbols] = useState([]);

  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  const [isSaving, setIsSaving] = useState(false);
  // useEffect(() => {
  //   axios2
  //     .get(`https://mt5mailchimp.creo-dev.com/api/mt5-mailchimp?symbols=*`)
  //     .then((result) => {
  //       setOptionsSymbols((currentObjects) =>
  //         currentObjects.concat([
  //           ...result.data.statData.answer.map((item, index) => {
  //             return {
  //               label: item.Symbol,
  //               value: String(item.Symbol),
  //             };
  //           }),
  //         ])
  //       );
  //       axios
  //         .get(`/admin/v1/asset-details/${symbolId}`)
  //         .then((res) => {
  //           console.log(res.data);
  //           setSelectedSymbol("GBPUSD");
  //         })
  //         .catch((err) => console.log(""));
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    let responseSymbols = "";
    let responseItem = "";
    try {
      responseSymbols = await axios2.get(
        `https://mt5mailchimp.creo-dev.com/api/mt5-mailchimp?symbols=*`
      );
      setOptionsSymbols((currentObjects) =>
        currentObjects.concat([
          ...responseSymbols.data.statData.map((item, index) => {
            return {
              label: item,
              value: String(item),
            };
          }),
        ])
      );
    } catch (error) {
      console.log(error);
    }

    try {
      responseItem = await axios.get(`/admin/v1/asset-details/${symbolId}`);
      console.log("sss=", responseItem.data.data.symbol);
      setSelectedSymbol({
        label: responseItem.data.data.symbol,
        value: responseItem.data.data.symbol,
      });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Page
      title="Edit Symbol"
      breadcrumbs={[{ url: `/live-market-prices/${priceId}/symbols` }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          {/* <SelectSearch
            options={optionsSymbols}
            value={selectedSymbol}
            onChange={handleSelectChangeSymbol}
            name="language"
            placeholder="Please choose an option"
          /> */}

          <Select
            label="Symbol"
            options={optionsSymbols}
            onChange={handleSelectChangeSymbol}
            value={selectedSymbol}
            placeholder="Please select"
          />
          <Checkbox
            label="Is active"
            checked={checkedShow}
            onChange={handleChangeShow}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/v1/asset-details/${symbolId}`)
                .then((result) => {
                  history.push(`/live-market-prices/${priceId}/symbols`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    setIsSaving(true);
    const form_data = new FormData();
    form_data.append("is_ative", checkedShow ? 1 : 0);
    form_data.append("symbol", selectedSymbol.value);
    form_data.append("asset_id", priceId);
    form_data.append("_method", "PATCH");
    axios
      .post(`/admin/v1/asset-details/${symbolId}`, form_data)
      .then((res) => {
        history.push(`/live-market-prices/${priceId}/symbols`);
      })
      .catch((err) => console.log(""));
  }
};
export default Edit;
