import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  Spinner,
} from "@shopify/polaris";
import axios from "../Assets/Libraries/axios";
import emptyListImage from "../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import NavigationLayout from "../Components/NavigationLayout";
import moment from "moment";
const NewsletterSubscribersList = () => {
  const perPage = 100;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  useEffect(() => {
    axios
      .get(`/admin/v1/users/subscribed?page=${currentPage}&per_page=${perPage}`)
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        console.log(result.data.data.total);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        setItems(
          result.data.data.data.map((item) => [
            item.id,
            item?.first_name && item.first_name,
            item?.last_name && item.last_name,
            item?.email && item.email,
            item?.created_at &&
              moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
          ])
        );
      })
      .catch((err) => setIsLoading(false));
  }, [currentPage, sortState, availability, queryValue]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Name", value: "Name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  return (
    <NavigationLayout>
      <Page fullWidth title="Newsletter Subscribers">
        <Card>
          {loadingMarkup}
          {/* <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section> */}
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">First Name</TextStyle>,
              <TextStyle variation="strong">Last Name</TextStyle>,
              <TextStyle variation="strong">Email</TextStyle>,
              <TextStyle variation="strong">Created at</TextStyle>,
            ]}
            rows={items}
            sortable={[false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            verticalAlign="middle"
          />

          {emtyState}
        </Card>
        {totalPages > 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              size="large"
            />
          </div>
        )}
      </Page>
    </NavigationLayout>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return value.map((val) => `Filter by ${val}`).join(", ");
      case "productType":
        return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default NewsletterSubscribersList;
