import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Image,
  DisplayText,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
} from "@shopify/polaris";
import Cookies from "js-cookie";
import axios from "../Assets/Libraries/axios";
import history from "../Assets/Libraries/history";
import emptyIcon from "../Assets/Images/emptyList.svg";
import NavigationLayout from "../Components/NavigationLayout";

export default function Pages() {
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortState, setSortState] = useState("ascending");
  const [items, setItems] = useState([]);
  let arrayOfObjects = [];
  let newArray = [];

  useEffect(() => {
    getData();
  }, []);
  function getData() {
    axios
      .get("/admin/v1/pages")
      .then((result) => {
        if (result.data.data.length === 0) {
          setIsEmpty(true);
          // toggleIsEmpty()
        }
        setIsLoading(false);
        arrayOfObjects = result.data.data.map((item) => [
          item.id,
          item.title.en,
          item.title.ar,
          item.created_at,
          item.updated_at,
        ]);
        setItems(
          result.data.data.map((item) => [
            item.id,
            item.title.en,
            item.alias,
            item.created_at,
            item.updated_at,
            <ButtonGroup>
              <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>
            </ButtonGroup>,
          ])
        );
      })
      .catch((err) => console.log(err));
  }

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyIcon}></Image>{" "}
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : null;

  return (
    <NavigationLayout>
      {loadingMarkup}
      {/* <Frame>
    <Loading />
</Frame> */}
      <Page
        fullWidth
        title="List Of Pages"
        primaryAction={{
          content: "Create Page",
          url: "/pages/add",
        }}
      >
        <Card>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              <TextStyle variation="strong">ID</TextStyle>,
              <TextStyle variation="strong">Title</TextStyle>,
              <TextStyle variation="strong">Alias</TextStyle>,
              <TextStyle variation="strong">Created At</TextStyle>,
              <TextStyle variation="strong">Updated At</TextStyle>,
              <TextStyle variation="strong"></TextStyle>,
            ]}
            rows={items}
            sortable={[true, false, false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={1}
            onSort={handleSort}
          />
          {emtyState}
        </Card>
      </Page>
    </NavigationLayout>
  );
  function handleSort() {
    if (sortState === "ascending") {
      setSortState("descending");
      axios
        .get("/admin/v1/pages?sort=id")
        .then((result) => {
          arrayOfObjects = result.data.data.map((item) => [
            item.id,
            item.alias,
            item.title.en,
            item.title.ar,
            item.created_at,
            item.updated_at,
          ]);
          setItems(
            result.data.data.map((item) => [
              item.id,
              item.alias,
              item.created_at,
              item.updated_at,
              <ButtonGroup>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
        })
        .catch((err) => console.log(err));
    } else {
      setSortState("descending");
      axios
        .get("/admin/v1/pages?sort=-id")
        .then((result) => {
          arrayOfObjects = result.data.data.map((item) => [
            item.id,
            item.alias,
            item.title.en,
            item.title.ar,
            item.created_at,
            item.updated_at,
          ]);
          setItems(
            result.data.data.map((item) => [
              item.id,
              item.alias,
              item.created_at,
              item.updated_at,
              <ButtonGroup>
                <Button id={String(item.id)} onClick={(e) => handleEdit(e)}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
        })
        .catch((err) => console.log(err));
    }
  }
  function handleEdit(e) {
    let currentID = e.currentTarget.id;
    history.push("/pages/" + currentID);
  }
  function handleUp(event) {
    let currentID = event.currentTarget.id;

    const objBody = {
      sort: "up",
    };
    axios
      .post(
        "admin/v1/support/category-faq/" +
          Cookies.get("categID") +
          "/faq/" +
          currentID +
          "/sort",
        objBody
      )
      .then((result) => {
        if (
          arrayOfObjects.indexOf(
            findArrayElementById(arrayOfObjects, currentID)
          ) -
            1 !=
          -1
        ) {
          newArray = swapArrayElements(
            arrayOfObjects,
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ),
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ) - 1
          );
          setItems(
            newArray.map((item) => [
              newArray[0],
              newArray[1],
              newArray[2],
              newArray[4],
              newArray[5],
              <Button id={String(newArray[0])} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>,
            ])
          );
        }
      })
      .catch((err) => console.log(err));

    //setItems(swapArrayElements(items,1,2))
  }
  function handleDown(event) {
    let currentID = event.currentTarget.id;

    const objBody = {
      sort: "up",
    };
    axios
      .post(
        "admin/v1/support/category-faq/" +
          Cookies.get("categID") +
          "/faq/" +
          currentID +
          "/sort",
        objBody
      )
      .then((result) => {
        if (
          arrayOfObjects.indexOf(
            findArrayElementById(arrayOfObjects, currentID)
          ) +
            1 !=
          -1
        ) {
          newArray = swapArrayElements(
            arrayOfObjects,
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ),
            arrayOfObjects.indexOf(
              findArrayElementById(arrayOfObjects, currentID)
            ) + 1
          );
          setItems(
            newArray.map((item) => [
              newArray[0],
              newArray[1],
              newArray[2],
              newArray[4],
              newArray[5],
              <Button id={String(newArray[0])} onClick={(e) => handleEdit(e)}>
                Edit
              </Button>,
            ])
          );
        }
      })
      .catch((err) => console.log(err));
  }
  function handleViewDetails(event) {
    let currentID = event.currentTarget.id;
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element[0]) === parseInt(id);
    });
  }
  function swapArrayElements(a, x, y) {
    if (a.length === 1) return a;
    a.splice(y, 1, a.splice(x, 1, a[y])[0]);
    return a;
  }
}
