import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../Assets/Libraries/history";
import EditKycForm from "./EditKycForm";
import { AppProvider } from "@shopify/polaris";
import LeadDetails from "./LeadDetails";
import EditProfile from "./EditProfile";
function MainEditPromofixLeads() {
  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/promofix-leads/:id">
            <LeadDetails type={"promofix"} />
          </Route>
          <Route path="/promofix-leads/:id/editprofile">
            <EditProfile type={"promofix"} />
          </Route>
          <Route path="/promofix-leads/:id/editkycform">
            <EditKycForm type={"promofix"} />
          </Route>
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default MainEditPromofixLeads;
