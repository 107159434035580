import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../Assets/Libraries/history";
import List from "./List";
import NavigationLayout from "../Components/NavigationLayout";
function Campaign() {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/campaign" component={List} />
        </Switch>
      </Router>
    </NavigationLayout>
  );
}
export default Campaign;
